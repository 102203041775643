import { styled } from '@mui/material/styles';
import { Box, BoxProps, Typography } from '@mui/material';

export const ThumbsContainer = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
}));

export const Thumbs = styled(Box)<BoxProps>(() => ({
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
  position: 'relative'
}));

export const ThumbInner = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
}));

export const WarningSubText = styled(Typography)(() => ({
  fontWeight: 'bold',
  fontSize: '12px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const ContainerDrop = styled('div')(() => ({
  backgroundColor: 'rgb(250, 250, 250)',
  transition: 'border .24s ease-in-out',
  borderWidth: '2px',
  borderRadius: '2px',
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  padding: '20px',
  cursor: 'pointer'
}));

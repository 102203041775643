import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useGetReportsByUserIdQuery } from 'store/API/report/reportApi';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useQuery } from 'routes/routes.util';
import { useMyReportDelete } from './useMyReportDelete.hook';
import { PageModal } from 'components/PageModal/PageModal';
import ReportCardProgress from 'components/ReportCard/ReportCardProgress';
import { DataNotFound } from 'components/DataNotFound/DataNotFound';
import { Box, Typography } from '@mui/material';
import { ReportCard } from 'components/ReportCard/ReportCard';
import { AnchorMenu } from 'components/AnchorMenu/AnchorMenu';
import { ModalConfirmation } from 'components/ModalConfirmation/ModalConfirmation';
import { ModalEditMyReport } from './ModalEditMyReport';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

export const MyReports = () => {
  const { isLoading, data, isError } = useGetReportsByUserIdQuery();
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = useQuery();

  const closeNewModal = useCallback(() => navigate('/minhas-postagens', { replace: true }), []);

  const onHandleGoToPublish = useCallback(() => navigate('/publicar', { replace: true }), []);

  const { isLoadingDelete, onHandleDeleteReport } = useMyReportDelete({ closeNewModal });

  const closeModalOnShadow = useCallback(() => navigate('/menu', { replace: true }), []);

  const openNewModal = useCallback(
    (id: string, path: string) => {
      const params = new URLSearchParams(search);
      params.append(path, id);
      navigate(`?${params.toString()}`, { replace: true });
    },
    [navigate, search]
  );

  const options = useCallback(
    (id: string) => [
      {
        label: 'Editar',
        icon: <EditIcon fontSize="small" />,
        action: () => openNewModal(id, 'edit')
      },
      {
        label: 'Deletar',
        icon: <DeleteIcon fontSize="small" />,
        action: () => openNewModal(id, 'delete')
      }
    ],
    [openNewModal]
  );

  const reportSelected = useMemo(() => {
    const id = query.get('delete') ?? query.get('edit');

    if (!id || !data) return undefined;

    return data.find((e) => e._id === id);
  }, [query, data]);

  const isDataEmpty = data && data.length < 1;
  const isDataReady = !isError && !isLoading && !isLoadingDelete && data && data.length > 0;
  const hasError = isError && !isLoading && !isLoadingDelete;

  const showFakeReportCard = isLoading || isLoadingDelete;

  const openDelete = Boolean(query.has('delete')) && Boolean(reportSelected);
  const openEdit = Boolean(query.has('edit')) && Boolean(reportSelected);

  return (
    <PageModal
      pageModalHeaderProps={{ title: 'Minhas postagens', closeShadow: closeModalOnShadow }}
      modalProps={{ open: true }}
    >
      {showFakeReportCard && [1, 2, 3].map(() => <ReportCardProgress showButtonClose={false} />)}
      {isDataEmpty && (
        <DataNotFound
          title="Não foi possível encontrar suas postagens!"
          message="Vá em frente e crie o seu primeiro!"
          buttonTitle="Criar Publicação"
          action={onHandleGoToPublish}
        />
      )}
      {hasError && (
        <DataNotFound
          title="Não foi possível encontrar suas postagens!"
          message="Tente mais tarde"
        />
      )}
      {isDataReady && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }}>
          {data.map((e) => (
            <ReportCard
              headerButton={<AnchorMenu options={options(e._id)} />}
              report={e}
              key={e._id}
            />
          ))}
          <EndMessage />
        </Box>
      )}
      <ModalConfirmation
        onClickConfirm={onHandleDeleteReport}
        severity="warning"
        closeModal={closeNewModal}
        open={openDelete}
        title={'Excluir publicação'}
        isLoading={isLoadingDelete}
        message={reportSelected?.title ?? ''}
      />
      {reportSelected ? (
        <ModalEditMyReport open={openEdit} closeModal={closeNewModal} report={reportSelected} />
      ) : null}
    </PageModal>
  );
};

const EndMessage = () => (
  <Box
    sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
  >
    <TaskAltIcon />
    <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }} component="p">
      Você viu todas as suas publicações
    </Typography>
  </Box>
);

import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, IResponse } from '../api.util';

export interface IComment {
  _id: string;
  comment: string;
  user: { photo: string; name: string; email: string; _id: string };
  report_id: string;
  createdAt: string;
  updatedAt: string;
}

interface IUpdateCommentById {
  comment_id: string;
  comment: string;
  report_id: string;
}

export const commentApi = createApi({
  reducerPath: 'commentApi',
  baseQuery: baseQuery,
  tagTypes: ['Comment'],
  endpoints: (builder) => ({
    findCommentsByReportId: builder.query<IComment[], string>({
      query: (report_id) => ({
        url: `comment`,
        method: 'GET',
        params: { report_id }
      }),
      transformResponse: (response: IResponse<IComment[]>) => response.data,
      providesTags: (_, error, report_id) => (error ? [] : [{ type: 'Comment', id: report_id }])
    }),
    addCommentByReportId: builder.mutation<IComment, { report_id: string; comment: string }>({
      query: (data) => ({
        url: `comment`,
        method: 'POST',
        body: data
      }),
      transformResponse: (response: IResponse<IComment>) => response.data,
      invalidatesTags: (_, __, { report_id }) => [{ type: 'Comment', id: report_id }]
    }),
    deleteCommentById: builder.mutation<IComment, { comment_id: string; report_id: string }>({
      query: ({ comment_id }) => ({
        url: `comment`,
        method: 'DELETE',
        body: { comment_id }
      }),
      invalidatesTags: (_, __, { report_id }) => [{ type: 'Comment', id: report_id }]
    }),
    updateCommentById: builder.mutation<IComment, IUpdateCommentById>({
      query: ({ comment_id, comment }) => ({
        url: `comment`,
        method: 'PUT',
        body: { comment_id, comment }
      }),
      invalidatesTags: (_, __, { report_id }) => [{ type: 'Comment', id: report_id }]
    })
  })
});

export const {
  useFindCommentsByReportIdQuery,
  useAddCommentByReportIdMutation,
  useDeleteCommentByIdMutation,
  useUpdateCommentByIdMutation
} = commentApi;

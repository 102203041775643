import { Box, Button, Typography } from '@mui/material';
import loginImage from 'assets/login.jpg';
import { paddingDrawer } from 'pages/MenuDrawer/MenuDrawer.style';
import { useCallback } from 'react';
import { useGoToPage } from 'routes/routes.util';

export const LoginSection = () => {
  const goTo = useGoToPage();

  const onHandleOpenLoginPage = useCallback(() => goTo({ to: '/login' }), []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        width: '100%',
        alignItems: 'center',
        padding: paddingDrawer
      }}
    >
      <Typography sx={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }} component="p">
        Olá, faça seu login ou cadastre-se
      </Typography>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          maxWidth: '200px',
          maxHeight: '200px'
        }}
      >
        <img src={loginImage} height="100%" width={'100%'} />
      </Box>
      <Typography
        sx={{ fontSize: '12px', fontWeight: 'lighter', textAlign: 'center' }}
        component="p"
      >
        Pra ver suas postagens e ter uma experiência personalizada, acesse sua conta :)
      </Typography>
      <Button onClick={onHandleOpenLoginPage} variant="outlined">
        Entrar ou Criar conta
      </Button>
    </Box>
  );
};

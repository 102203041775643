import React from 'react';
import { MapContainer } from 'react-leaflet';
import { Outlet } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { MapLeaflet } from './Map';
import { Header } from '../../components/Header/Header';
import { useListeningAuthUser } from 'hooks/useListeningAuthUser.hook';
import { FabGoBackToReportForm } from './components/FabGoBackToReportForm';
import { MessageDoubleClickMap } from './components/MessageDoubleClickMap';
import { INITIAL_POSITION } from 'store/API/report/reportSlice';
import Div100vh from 'react-div-100vh';
import { GlobalLoading } from 'components/GlobalLoading/GlobalLoading';
import { BottomBar } from './components/BottomBar/BottomBar';

interface IHome {
  children?: React.ReactNode;
}

const Home: React.FC<IHome> = ({ children }) => {
  useListeningAuthUser();

  return (
    <SnackbarProvider maxSnack={1}>
      <Header />
      <BottomBar />
      <FabGoBackToReportForm />
      <MessageDoubleClickMap />
      <GlobalLoading />
      <Div100vh>
        <MapContainer
          zoomControl={false}
          style={{ minHeight: '100%' }}
          zoom={13}
          scrollWheelZoom={true}
          center={INITIAL_POSITION}
        >
          {children}
          <MapLeaflet />
        </MapContainer>
      </Div100vh>
      <Outlet />
    </SnackbarProvider>
  );
};

export default Home;

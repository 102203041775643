import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useGoToPage } from 'routes/routes.util';
import React, { useCallback, useMemo } from 'react';
import { LogoHeader } from './Header.style';
import { useAppSelector } from 'store/storeHooks';
import { AvatarProfileHeader } from './components/AvatarProfileHeader';
import LoginIcon from '@mui/icons-material/Login';
import logo from 'assets/logo_web.png';

export const Header: React.FC = () => {
  const user = useAppSelector((e) => e.user.user);
  const citySelected = useAppSelector((e) => e.report.citySelected);

  const cityName = useMemo(() => (citySelected ? citySelected.name : null), [citySelected]);

  const showMap = useAppSelector((e) => e.map.showMap);

  const goTo = useGoToPage();

  const onHandleOpenLoginPage = useCallback(() => goTo({ to: '/login' }), []);

  const onHandleOpenFeed = useCallback(() => goTo({ to: '/feed' }), []);

  if (showMap) {
    return <></>;
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="absolute" elevation={6}>
          <Toolbar>
            <LogoHeader>
              <img height={48} src={logo} />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 'bold' }}>Eu</Typography>
                <Typography sx={{ fontWeight: 'bold' }}>Fiscal</Typography>
              </Box>
            </LogoHeader>
            <Box
              sx={{
                flex: 2,
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'center'
              }}
            >
              <Button
                onClick={onHandleOpenFeed}
                color="inherit"
                variant="outlined"
                startIcon={<LocationOnIcon />}
              >
                O que está acontecendo {cityName ? `em ${cityName}` : 'na minha cidade'}?
              </Button>
            </Box>
            {user ? (
              <AvatarProfileHeader />
            ) : (
              <Box sx={{ flex: 1, display: 'flex', justifyContent: 'end' }}>
                <Button
                  color="inherit"
                  startIcon={<LoginIcon />}
                  onClick={onHandleOpenLoginPage}
                  variant="outlined"
                >
                  Entrar
                </Button>
              </Box>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};

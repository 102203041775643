import { styled } from '@mui/material/styles';
import { Box, BoxProps, Typography, TypographyStyle } from '@mui/material';

export const LogoHeader = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: '8px',
  flex: 1
}));

import { Box, InputAdornment, Typography } from '@mui/material';
import { GenericTextField } from 'components/GenericTextField/GenericTextField';
import { PageModal } from 'components/PageModal/PageModal';
import { useCallback } from 'react';
import { useGoToPage } from 'routes/routes.util';
import EmailIcon from '@mui/icons-material/Email';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';

const schemaResetPassword = Yup.object({
  email: Yup.string()
    .email('Email incompleto')
    .max(255)
    .required('É necessário informar um e-mail.')
}).defined();

export const ResetPassword = () => {
  const goTo = useGoToPage();

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: schemaResetPassword,
    onSubmit: ({ email }) => onHandleSubmiResetPassword(email)
  });

  const onHandleSubmiResetPassword = useCallback((email: string) => {}, []);

  const onHandleCloseModel = useCallback(() => goTo({ to: '/login' }), []);

  return (
    <PageModal
      modalProps={{
        open: true
      }}
      pageModalHeaderProps={{
        title: 'Trocar a senha',
        closeShadow: onHandleCloseModel
      }}
      closeModalOnShadow={onHandleCloseModel}
      containerPageModalSx={{ maxWidth: 420 }}
    >
      <Typography
        component="p"
        sx={{
          fontSize: '0.8rem',
          fontWeight: 'lighter',
          textAlign: 'justify'
        }}
      >
        Identifique-se para receber um e-mail com as instruções e o link para criar uma nova senha.
      </Typography>
      <GenericTextField
        label="Email"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          )
        }}
        id="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'end' }}>
        <LoadingButton variant="outlined">Recuperar senha</LoadingButton>
      </Box>
    </PageModal>
  );
};

import { Box, Button } from '@mui/material';
import { PageModal } from 'components/PageModal/PageModal';
import { FC, useCallback, useState } from 'react';
import { UploadImage } from './UploadImage';

export interface IPhotoSelected {
  file?: File;
  preview: string;
}

interface ModalUploadImageProps {
  open: boolean;
  closeModal: () => void;
  initialImage: IPhotoSelected | null;
  onHandleModalSaveImage: (img: IPhotoSelected | null) => void;
}

export const ModalUploadImage: FC<ModalUploadImageProps> = ({
  open,
  closeModal,
  initialImage,
  onHandleModalSaveImage
}) => {
  const [fileSelected, setFileSelected] = useState<IPhotoSelected | null>(initialImage);

  const onHandleSave = useCallback(() => {
    onHandleModalSaveImage(fileSelected);
  }, [fileSelected]);

  return (
    <PageModal
      pageModalHeaderProps={{
        title: 'Foto de perfil',
        closeShadow: closeModal
      }}
      closeModalOnShadow={closeModal}
      containerPageModalSx={{ maxWidth: 420 }}
      modalProps={{ open: open }}
    >
      <UploadImage fileSelected={fileSelected} setFileSelected={setFileSelected} />
      <Box sx={{ gap: '8px', display: 'flex', flexDirection: 'row' }}>
        <Button onClick={closeModal} variant="outlined" color="error">
          Voltar
        </Button>
        <Button onClick={onHandleSave} variant="outlined">
          Salvar
        </Button>
      </Box>
    </PageModal>
  );
};

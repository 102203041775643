import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import { Box, Button, Typography } from '@mui/material';
import React, { FC } from 'react';
import empty_reports from '../../assets/empty_reports.jpg';
import defaultImg from '../../assets/defaultImg.jpg';

interface DataNotFoundProps {
  title: string;
  message?: string;
  action?: () => void;
  buttonTitle?: string;
  image?: 'empty_reports';
}

const imagesMap = new Map<string, any>([
  ['empty_reports', empty_reports],
  ['defaultImg', defaultImg]
]);

export const DataNotFound: FC<DataNotFoundProps> = ({
  title,
  message,
  action,
  buttonTitle,
  image = 'defaultImg'
}) => {
  const getImage = imagesMap.get(image) ?? imagesMap.get('defaultImg');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        alignItems: 'center',
        gap: '0.5rem',
        width: '100%'
      }}
    >
      <Box sx={{ display: 'flex', maxHeight: '200px', maxWidth: '200px' }}>
        <img src={getImage} width="100%" height="100%" style={{ borderRadius: '25%' }} />
      </Box>
      <Typography
        sx={{ fontSize: '0.8rem', fontWeight: 'bold', textAlign: 'center' }}
        component="p"
      >
        {title}
      </Typography>
      {message && (
        <Typography
          sx={{ fontSize: '0.8rem', fontWeight: 'lighter', textAlign: 'center' }}
          component="p"
        >
          {message}
        </Typography>
      )}
      {Boolean(action) && (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Button onClick={action} startIcon={<PlaylistAddCircleIcon />} variant="contained">
            {buttonTitle}
          </Button>
        </Box>
      )}
    </Box>
  );
};

import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from 'store/store';

export interface IResponse<T> {
  data: T;
  status: string;
  action?: string;
}

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_URL,
  prepareHeaders: (headers, { getState }) => {
    const state = getState() as RootState;
    const token = state.user.token;

    if (token) {
      headers.set('authorization', token);
    }

    return headers;
  }
});

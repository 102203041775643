import { auth } from 'firebase-config';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutesPaths, useGoToPage } from 'routes/routes.util';
import { setCitySelected } from 'store/API/report/reportSlice';
import { fixedCacheKeyUser, useGetUserMutation, USER_API_ACTION } from 'store/API/user/userApi';
import { setCredentials, setLoginLoading, signOutUser } from 'store/API/user/userSlice';
import { useAppDispatch } from 'store/storeHooks';

export function useListeningAuthUser() {
  const [getUser] = useGetUserMutation({ fixedCacheKey: fixedCacheKeyUser });
  const location = useLocation();
  const goTo = useGoToPage();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onHandleNavigateToUpdateProfile = useCallback((params?: string) => {
    goTo({
      to: '/atualizar-perfil',
      params: params ?? ''
    });
  }, []);

  const onHandleGoToUserUpdate = useCallback(() => {
    const pathsNotAllowed = ['/', '/login', '/atualizar-perfil'] as RoutesPaths[];

    return !pathsNotAllowed.includes(location.pathname as RoutesPaths);
  }, []);

  const useHasPathToGo = useCallback(() => {
    const userFrom = onHandleGoToUserUpdate();
    let userNavigate = '';

    if (userFrom) {
      const urlParams = new URLSearchParams(location.search);
      const newParam = location.pathname.replace('/', '');
      urlParams.append('irPara', newParam);
      userNavigate = '?' + urlParams.toString();
    }

    return userNavigate;
  }, []);

  const generateUrl = useCallback(() => {
    const urlParams = new URLSearchParams(location.search);
    const path = urlParams.get('irPara') ?? '';

    let url = '/' + path;

    if (path) {
      urlParams.delete('irPara');

      if (urlParams.values.length) {
        url += '?' + urlParams.toString();
      }
    } else if (onHandleGoToUserUpdate()) {
      urlParams.delete('irPara');
      let params = '';
      if (Array.from(urlParams.values()).length > 0) {
        params += '?' + urlParams.toString();
      }

      return location.pathname + params;
    }

    return url;
  }, []);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        dispatch(setLoginLoading({ loading: true }));

        const token = await user.getIdToken(true);
        dispatch(setCredentials({ token: token }));

        try {
          const userData = await getUser().unwrap();

          if (userData.status === 'notOk' && userData.action === USER_API_ACTION) {
            const userFrom = useHasPathToGo();

            onHandleNavigateToUpdateProfile(userFrom);
          }

          if (userData.status === 'ok') {
            const { city } = userData.data;
            dispatch(setCitySelected({ city }));

            navigate(generateUrl());
          }
        } catch (e) {
          dispatch(signOutUser());
        }
      } else {
        dispatch(signOutUser());
      }

      dispatch(setLoginLoading({ loading: false }));
    });
  }, []);
}

import { PageModal } from 'components/PageModal/PageModal';
import { ReportCard } from 'components/ReportCard/ReportCard';
import ReportCardProgress from 'components/ReportCard/ReportCardProgress';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetReportByIdQuery } from 'store/API/report/reportApi';

export const Report = () => {
  const { id = '' } = useParams<'id'>();
  const { data, isError, isLoading, isFetching } = useGetReportByIdQuery(id, { skip: !id });

  const isReady = useMemo(() => data && (!isLoading || !isFetching), [data, isLoading, isFetching]);

  return (
    <PageModal containerPageModalSx={{ padding: '0px' }} modalProps={{ open: true }}>
      {isLoading || isFetching ? <ReportCardProgress /> : null}
      {isReady ? <ReportCard report={data!} /> : null}
    </PageModal>
  );
};

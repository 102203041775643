import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useQuery } from 'routes/routes.util';
import { useDeleteReportMutation } from 'store/API/report/reportApi';

export function useMyReportDelete({ closeNewModal }: { closeNewModal: () => void }) {
  const [deleteReport, { isLoading: isLoadingDelete }] = useDeleteReportMutation();
  const { enqueueSnackbar } = useSnackbar();

  const query = useQuery();

  const onHandleDeleteReport = useCallback(async () => {
    const id = query.get('delete');

    if (!id) return;

    try {
      await deleteReport(id).unwrap();

      enqueueSnackbar('A postagem foi deletada!', { variant: 'success' });
      closeNewModal();
    } catch (e) {
      enqueueSnackbar('Não foi possível excluir esta postagem. Tente mais tarde!', {
        variant: 'error'
      });
    }
  }, [query, enqueueSnackbar, closeNewModal]);

  return { isLoadingDelete, onHandleDeleteReport };
}

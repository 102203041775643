import { Divider } from '@mui/material';
import React, { FC } from 'react';
import {
  ContainerCustomDivider,
  ContainerLineCustomDivider,
  TextCustomDivider
} from './CustomDivider.style';

interface CustomDividerProps {
  text?: string;
}

export const CustomDivider: FC<CustomDividerProps> = ({ text }) => {
  return (
    <ContainerCustomDivider>
      <ContainerLineCustomDivider>
        <Divider sx={{ display: 'flex', height: '1px', width: '100%' }} />
      </ContainerLineCustomDivider>
      {text && <TextCustomDivider component="div">{text}</TextCustomDivider>}
      <ContainerLineCustomDivider>
        <Divider sx={{ display: 'flex', height: '1px', width: '100%' }} />
      </ContainerLineCustomDivider>
    </ContainerCustomDivider>
  );
};

import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { TextHighlighted } from './TextTypeOfFormLoginPage.style';

interface TextTypeOfFormLoginPageProps {
  isLogin: boolean;
  onHandleTextTypeOfForm: () => void;
}

export const TextTypeOfFormLoginPage: FC<TextTypeOfFormLoginPageProps> = ({
  isLogin,
  onHandleTextTypeOfForm
}) => {
  return (
    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
      <Typography
        sx={{
          fontSize: '12px',
          display: 'flex',
          justifyContent: 'center',
          cursor: 'pointer'
        }}
        component="div"
        onClick={onHandleTextTypeOfForm}
      >
        {isLogin ? 'Não tem uma conta?' : 'Eu já tenho uma conta!'}
        <TextHighlighted>&nbsp;{isLogin ? 'Cadastre-se' : 'Entrar'}</TextHighlighted>
      </Typography>
    </Box>
  );
};

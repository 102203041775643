import { FC, useCallback, useMemo } from 'react';
import { Avatar, Box, CircularProgress, Typography } from '@mui/material';
import { IUser } from 'store/API/user/userApi';
import { useAppSelector } from 'store/storeHooks';
import {
  useAddCommentByReportIdMutation,
  useFindCommentsByReportIdQuery
} from 'store/API/comment/commentApi';
import { CommentInput } from './components/CommentInput';
import { CommentCard } from './components/CommentCard';

interface CommentSectionProps {
  report_id: string;
}

export const CommentSection: FC<CommentSectionProps> = ({ report_id }) => {
  const { isLoading: isLoadingComments, data = [] } = useFindCommentsByReportIdQuery(report_id);
  const [addComment, { isLoading: isLoadingAddComment }] = useAddCommentByReportIdMutation();
  const user = useAppSelector((e) => e.user.user);
  const { photo = '' } = user ?? ({} as IUser);

  const isLoading = useMemo(
    () => isLoadingComments || isLoadingAddComment,
    [isLoadingAddComment, isLoadingComments]
  );

  const onHandleSubmitComment = useCallback(
    (comment: string) => {
      addComment({ comment, report_id });
    },
    [report_id]
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          gap: 2,
          justifyContent: 'center'
        }}
      >
        <Box sx={{ display: 'flex', height: '100%' }}>
          <Avatar src={photo} imgProps={{ referrerPolicy: 'no-referrer' }} />
        </Box>
        <CommentInput
          report_id={report_id}
          isLoading={isLoading}
          onHandleSubmitComment={onHandleSubmitComment}
        />
      </Box>
      {isLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <CircularProgress size={32} />
        </Box>
      )}
      {!isLoading && data.map((e) => <CommentCard key={e._id} commentData={e} />)}
      {!isLoading && data.length < 1 && (
        <Typography
          sx={{ fontSize: '14px', fontWeight: 'lighter', textAlign: 'center' }}
          component="p"
        >
          Seja a primeira pessoa a comentar
        </Typography>
      )}
    </Box>
  );
};

import { DataNotFound } from 'components/DataNotFound/DataNotFound';
import { PageModal } from 'components/PageModal/PageModal';
import { useCallback } from 'react';
import { useGoToPage } from 'routes/routes.util';

export const PageNotFound = () => {
  const goTo = useGoToPage();

  const onHandleCloseModal = useCallback(() => goTo({ to: '/' }), []);

  return (
    <PageModal
      closeModalOnShadow={onHandleCloseModal}
      pageModalHeaderProps={{ title: 'Página não encontrada!', closeShadow: onHandleCloseModal }}
      modalProps={{ open: true }}
    >
      <DataNotFound
        action={onHandleCloseModal}
        buttonTitle="Home"
        title="A página que você está tentando acessar não existe"
      />
    </PageModal>
  );
};

import { Alert, AlertColor, AlertTitle, Box } from '@mui/material';
import React, { FC } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { PageModal } from 'components/PageModal/PageModal';

interface ModalConfirmationProps {
  closeModal: () => void;
  onClickConfirm: () => void;
  title: string;
  message?: string;
  open: boolean;
  severity: AlertColor;
  isLoading?: boolean;
}

export const ModalConfirmation: FC<ModalConfirmationProps> = ({
  closeModal,
  title,
  message,
  open,
  severity,
  onClickConfirm,
  isLoading = false
}) => {
  return (
    <PageModal
      closeModalOnShadow={closeModal}
      modalProps={{
        open: open,
        disableScrollLock: false,
        hideBackdrop: false
      }}
    >
      <Alert severity={severity} sx={{ display: 'flex', flex: 1, width: '100%' }}>
        <AlertTitle>{title}</AlertTitle>
        {message}
      </Alert>
      <Box sx={{ display: 'flex', gap: '8px', justifyContent: 'end', width: '100%' }}>
        <LoadingButton loading={isLoading} variant="outlined" onClick={closeModal}>
          Cancel
        </LoadingButton>
        <LoadingButton
          loading={isLoading}
          variant="outlined"
          color="error"
          onClick={onClickConfirm}
        >
          Confirm
        </LoadingButton>
      </Box>
    </PageModal>
  );
};

import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, IResponse } from '../api.util';
import { ICity } from '../cities/citiesApi';

export interface ReportsIds {
  [key: string]: string;
}

export interface IUser {
  email: string;
  name: string;
  photo: string;
  _id: string;
  city: ICity;
  createdAt: string;
  reports: string[];
  reports_ids: ReportsIds;
}

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getUser: builder.mutation<IResponse<IUser>, void>({
      query: () => `user`
    }),
    createUser: builder.mutation<IResponse<IUser>, FormData>({
      query: (data) => ({
        method: 'POST',
        url: 'user',
        body: data
      })
    })
  })
});

export const fixedCacheKeyUser = 'getUser';
export const USER_API_ACTION = 'update_profile';

export const { useGetUserMutation, useCreateUserMutation } = userApi;

import { Avatar, Badge, Box, Typography } from '@mui/material';
import { GenericTextField } from 'components/GenericTextField/GenericTextField';
import { PageModal } from 'components/PageModal/PageModal';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import AutoCompleteInput from 'components/AutoCompleteInput/AutoCompleteInput';
import { useSelectCity } from 'hooks/useSelectCity.hook';
import { useCallback, useEffect, useMemo, useState } from 'react';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { IPhotoSelected, ModalUploadImage } from 'components/UploadImage/ModalUploadImage';
import { auth } from 'firebase-config';
import { ISchemaCreateUser, useCreateProfile } from './useCreateProfile.hook';
import { useAppSelector } from 'store/storeHooks';
import { useGoToPage } from 'routes/routes.util';

const schemaCreateUser = Yup.object().shape({
  name: Yup.string().max(128).required('Digite seu nome'),
  state: Yup.number().required('Selecione o estado').typeError('Selecione o estado').nullable(true),
  city: Yup.string().when(['state'], {
    is: (state: number | null) => {
      return !Boolean(state);
    },
    then: Yup.string().required('Selecione o estado primeiro').nullable(),
    otherwise: Yup.string().required('Selecione a cidade').nullable()
  })
});

export const UpdateProfile = () => {
  const user = useAppSelector((e) => e.user.user);
  const [openModalImage, setOpenModalImage] = useState(false);
  const [email, setEmail] = useState(user ? user.email : '');

  const goTo = useGoToPage();

  const [imageSelected, setImageSelected] = useState<IPhotoSelected | null>(
    user && user.photo ? { preview: user.photo } : null
  );
  const { onHandleCreateUser, isLoadingUserUpdate } = useCreateProfile();

  const initialValues: ISchemaCreateUser = useMemo(
    () =>
      user
        ? { name: user.name, city: user.city._id, state: user.city.uf }
        : {
            name: '',
            city: null,
            state: null
          },
    [user]
  );

  const initialCity = useMemo(() => user?.city ?? null, [user]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schemaCreateUser,
    onSubmit: (values) => {
      onHandleCreateUser(values, imageSelected);
    }
  });

  const photoUrl = useMemo(() => (imageSelected && imageSelected.preview) ?? '', [imageSelected]);

  const {
    states,
    stateSelected,
    onHandleState,
    cities,
    citySelected,
    onHandleCity,
    isCityLoading
  } = useSelectCity({
    formikValidateCity: formik.setFieldValue,
    formikValidateState: formik.setFieldValue,
    initialCity: initialCity,
    initialState: initialCity ? initialCity.uf : undefined,
    validateCityField: 'city',
    validateStateField: 'state'
  });

  const onHandleSubmitFormik = useCallback(async () => await formik.submitForm(), []);

  const onHandleCloseModalImageWithoutSave = useCallback(async () => setOpenModalImage(false), []);
  const onHandleOpenModalImage = useCallback(async () => setOpenModalImage(true), []);
  const onHandleModalSaveImage = useCallback(
    (img: IPhotoSelected | null) => {
      if (imageSelected && imageSelected.file) {
        URL.revokeObjectURL(imageSelected.preview);
      }
      setImageSelected(img);
      onHandleCloseModalImageWithoutSave();
    },
    [imageSelected]
  );

  const closeModalOnShadow = useCallback(() => goTo({ to: '/menu' }), [user]);

  useEffect(() => {
    if (!user) {
      auth.onAuthStateChanged((user) => {
        if (user) {
          const { email, displayName = '', photoURL = '' } = user;
          setEmail(email ?? '');
          formik.setFieldValue('name', displayName ?? '');

          if (photoURL) {
            setImageSelected({ preview: photoURL });
          }
        }
      });
    }
  }, [user]);

  return (
    <>
      <PageModal
        modalProps={{
          open: true
        }}
        pageModalHeaderProps={{
          title: user ? 'Atualizar perfil' : 'Informações básicas',
          closeShadow: user ? closeModalOnShadow : undefined
        }}
        closeModalOnShadow={user ? closeModalOnShadow : undefined}
        containerPageModalSx={{ maxWidth: 420 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '14px',
            width: '100%',
            justifyContent: 'center'
          }}
        >
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <Badge
              sx={{ cursor: 'pointer' }}
              overlap="circular"
              onClick={onHandleOpenModalImage}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              badgeContent={<AddAPhotoIcon fontSize="small" />}
            >
              <Avatar
                src={photoUrl}
                sx={{ width: '50px', height: '50px' }}
                imgProps={{ referrerPolicy: 'no-referrer' }}
              />
            </Badge>
          </Box>
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <Typography sx={{ fontSize: '12px', fontWeight: 'lighter' }} component="p">
              e-mail: {email}
            </Typography>
          </Box>
          <GenericTextField
            label="Nome"
            id="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <AutoCompleteInput
            options={states}
            id="state"
            label="Selecione o estado onde você mora"
            valueSelected={stateSelected}
            onChange={onHandleState}
            error={formik.touched.state && Boolean(formik.errors.state)}
            helperText={formik.touched.state && formik.errors.state}
          />
          <AutoCompleteInput
            id="city"
            isLoading={isCityLoading}
            options={cities}
            isDisabled={isCityLoading || cities.length < 1}
            label="Selecione a cidade onde você mora"
            valueSelected={citySelected}
            onChange={onHandleCity}
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city}
          />
          <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '8px' }}>
            {user ? (
              <LoadingButton
                onClick={closeModalOnShadow}
                sx={{ height: '36px', width: '100%' }}
                loading={isLoadingUserUpdate}
                color="error"
                variant="outlined"
              >
                Cancelar
              </LoadingButton>
            ) : null}
            <LoadingButton
              onClick={onHandleSubmitFormik}
              sx={{ height: '36px', width: '100%' }}
              loading={isLoadingUserUpdate}
              variant="outlined"
            >
              {user ? 'Salvar' : 'Enviar'}
            </LoadingButton>
          </Box>
          <ModalUploadImage
            onHandleModalSaveImage={onHandleModalSaveImage}
            initialImage={imageSelected}
            closeModal={onHandleCloseModalImageWithoutSave}
            open={openModalImage}
          />
        </Box>
      </PageModal>
    </>
  );
};

import WarningIcon from '@mui/icons-material/Warning';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import CampaignIcon from '@mui/icons-material/Campaign';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import RemoveRoadIcon from '@mui/icons-material/RemoveRoad';
import DeleteIcon from '@mui/icons-material/Delete';

export interface IconsProps {
  [key: string]: JSX.Element;
}

const sx = { width: '24px', height: '24px' };

export type TypesProblemsEnum = 'urban_problem' | 'sentiment_very_dissatisfied' | 'city_upseat';

export type SubTypesProblemsEnum = 'road' | 'rubbish';

type FontSize = 'small' | 'inherit' | 'large' | 'medium' | undefined;

export const subIcons = (fontSize?: FontSize) =>
  ({
    road: <RemoveRoadIcon sx={!fontSize ? sx : undefined} fontSize={fontSize} />,
    rubbish: <DeleteIcon sx={!fontSize ? sx : undefined} fontSize={fontSize} />
  } as IconsProps);

export const icons = (fontSize?: FontSize) =>
  ({
    urban_problem: <LocationCityIcon sx={!fontSize ? sx : undefined} fontSize={fontSize} />,
    city_upseat: (
      <SentimentVeryDissatisfiedIcon sx={!fontSize ? sx : undefined} fontSize={fontSize} />
    ),
    sentiment_very_dissatisfied: (
      <CampaignIcon sx={!fontSize ? sx : undefined} fontSize={fontSize} />
    ),
    default: <WarningIcon sx={!fontSize ? sx : undefined} fontSize={fontSize} />
  } as IconsProps);

export const getIcon = ({
  type,
  fontSize,
  subType
}: {
  type: string;
  subType?: string;
  fontSize?: FontSize;
}) => {
  const iconSub = subType ? subIcons(fontSize)[subType] : undefined;

  const icon = iconSub ?? icons(fontSize)[type];

  return icon ?? icons(fontSize)['default'];
};

import { TextareaAutosize } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TextAreaAutosizeStyled = styled(TextareaAutosize)(() => ({
  minHeight: '100px',
  display: 'flex',
  width: '100%',
  outline: 'none',
  maxWidth: '100%',
  borderRadius: '4px',
  padding: '16.5px 14px',
  backgroundColor: 'transparent'
}));

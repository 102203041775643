import { Marker, Popup, TileLayer, useMap, useMapEvents, ZoomControl } from 'react-leaflet';
import L, { divIcon } from 'leaflet';
import 'leaflet.markercluster';
import { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store/storeHooks';
import { mapDblClick } from 'store/API/map/mapSlice';
import { MarkerCluster } from './MarkerCluster';
import { renderToStaticMarkup } from 'react-dom/server';
import { getIcon } from 'utils/icons';
import {
  fixedCacheKeyReportsByLatLng,
  IGetReportsByLatLng,
  useGetReportsByLatLngMutation
} from 'store/API/report/reportApi';
import { Box, Button, Typography } from '@mui/material';
import { distanceTwoPoints } from 'utils/distanceTwoPoints';
import { useNavigate } from 'react-router-dom';

const IconMap = (type: string, subType?: string) => {
  const icon = getIcon({ type, subType });

  const iconMarkup = renderToStaticMarkup(<>{icon}</>);

  const customMarketIcon = divIcon({
    html: iconMarkup,
    className: ''
  });
  return customMarketIcon;
};

export const MapLeaflet = () => {
  const [getReports, { isError, data: reportsMaps = [] }] = useGetReportsByLatLngMutation({
    fixedCacheKey: fixedCacheKeyReportsByLatLng
  });
  const map = useMap();
  const dispatch = useAppDispatch();
  const showMap = useAppSelector((e) => e.map.showMap);

  const citySelected = useAppSelector((e) => e.report.citySelected);

  const navigate = useNavigate();

  const onHandleGoToReport = useCallback((e: string) => navigate(`/post/${e}`), []);

  const timerRef = useRef<any>(null);
  const onMoveStart = useRef<IGetReportsByLatLng>({ lat: 0, lng: 0 });

  useMapEvents({
    movestart: (e) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    },
    moveend: (e) => {
      const { lat, lng } = onMoveStart.current;
      const { lat: lat2, lng: lng2 } = e.target.getCenter() as IGetReportsByLatLng;

      const distance = distanceTwoPoints(lat, lat2, lng, lng2);

      if (distance > 10) {
        timerRef.current = setTimeout(() => {
          onMoveStart.current = { lat: lat2, lng: lng2 };
          getReports({ lat: lat2, lng: lng2 });
        }, 1500);
      }
    }
  });

  useEffect(() => {
    map.addEventListener('dblclick', (e) => {
      const { lat, lng } = e.latlng;
      dispatch(mapDblClick({ lat, lng }));
    });
  }, []);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (citySelected) {
      const {
        location: { coordinates }
      } = citySelected;
      map.flyTo([coordinates[0], coordinates[1]]);
    }
  }, [citySelected]);

  const shouldRenderMarkerCluster = !isError && reportsMaps.length > 0;

  return (
    <>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
      />
      {shouldRenderMarkerCluster && !showMap ? (
        <MarkerCluster>
          {reportsMaps.map((e) => (
            <Marker
              key={e._id}
              position={[e.location.coordinates[0], e.location.coordinates[1]]}
              icon={IconMap(e.typeProblem.type, e.subTypeProblem.type)}
              eventHandlers={{ click: (_) => {}, add: (popup) => {} }}
            >
              <Popup key={e._id}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '260px'
                  }}
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }} component="p">
                    {e.title}
                  </Typography>
                  <Button onClick={() => onHandleGoToReport(e._id)}>Ver mais</Button>
                </Box>
              </Popup>
            </Marker>
          ))}
        </MarkerCluster>
      ) : null}
    </>
  );
};

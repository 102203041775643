import { Box, Typography } from '@mui/material';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import { useAppSelector } from 'store/storeHooks';

export const MessageDoubleClickMap = () => {
  const showMap = useAppSelector((e) => e.map.showMap);

  if (!showMap) return null;

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '30px',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1300,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <TouchAppIcon sx={{ mr: 1 }} />
      <Typography
        component="div"
        sx={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}
      >
        clique duas vezes para selecionar o local
      </Typography>
    </Box>
  );
};

import { useEffect, useMemo, useRef, useState } from 'react';
import { ICity, useGetCitiesMutation } from 'store/API/cities/citiesApi';
import { IStateInBrazil, STATES_IN_BRAZIL } from 'utils/statesInBrazil';

interface useSelectCityProps {
  formikValidateState?: (field: string, e: any) => void;
  formikValidateCity?: (field: string, e: any) => void;
  initialState?: number;
  initialCity?: ICity | null;
  validateStateField?: string;
  validateCityField?: string;
}

export function useSelectCity<S = undefined>(props?: S | useSelectCityProps) {
  const {
    formikValidateState,
    formikValidateCity,
    initialState,
    initialCity = null,
    validateStateField = 'state',
    validateCityField = 'city'
  } = props ?? {};
  const initialCitySelected = useRef<ICity | null>(initialCity);
  const [stateSelected, setStateSelected] = useState<IStateInBrazil | null>(null);
  const [citySelected, setCitySelected] = useState<ICity | null>(initialCity);
  const [getCities, { isLoading: isCityLoading, data = [] }] = useGetCitiesMutation();

  const onHandleState = (event: any, value: IStateInBrazil | null) => {
    setStateSelected(value);

    if (citySelected) {
      onHandleCity(null, null);
    }

    if (formikValidateState) {
      formikValidateState(validateStateField, value ? value.id : value);
    }

    if (value) {
      getCities(value.id);
    }
  };

  const onHandleCity = (event: any, value: ICity | null) => {
    if (initialCitySelected.current) {
      setCitySelected(initialCitySelected.current);
    } else {
      setCitySelected(value);
    }

    if (formikValidateCity && initialCitySelected.current === null) {
      formikValidateCity(validateCityField, value ? value._id : value);
    }

    if (initialCitySelected.current) {
      initialCitySelected.current = null;
    }
  };

  useEffect(() => {
    const findState = STATES_IN_BRAZIL.find((e) => e.id === initialState);
    if (findState) {
      onHandleState(null, findState);
    }
  }, []);

  const showCities = useMemo(() => (stateSelected ? data : []), [stateSelected, data]);

  return {
    isCityLoading,
    cities: showCities,
    onHandleState,
    onHandleCity,
    stateSelected,
    citySelected,
    states: STATES_IN_BRAZIL
  };
}

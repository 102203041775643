import styled from '@emotion/styled';
import { Box, BoxProps } from '@mui/material';

export const ContentMenuDrawerStyled = styled(Box)<BoxProps>(() => ({
  gap: '1',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  height: '100%',
  overflowY: 'auto',
  marginTop: '8px'
}));

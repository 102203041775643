import { Avatar, Badge, Box, Paper, Typography } from '@mui/material';
import { ContainerPersonalInformation, ContainerUserInfo } from './UserInfo.style';
import EmailIcon from '@mui/icons-material/Email';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import FlagIcon from '@mui/icons-material/Flag';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { useAppSelector } from 'store/storeHooks';
import { useCallback, useMemo } from 'react';
import { STATES_IN_BRAZIL } from 'utils/statesInBrazil';
import { formatMonthDate } from 'utils/utils.hooks';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { useGoToPage } from 'routes/routes.util';

export const UserInfo = () => {
  const user = useAppSelector((e) => e.user.user);
  const {
    email,
    name,
    photo = '',
    city: { uf, name: cityName },
    createdAt
  } = user!;

  const goTo = useGoToPage();

  const stateName = useMemo(() => STATES_IN_BRAZIL.find((e) => e.id === uf)?.name ?? '', []);

  const date = formatMonthDate(createdAt);

  const onHandleGotoUpdateProfile = useCallback(() => goTo({ to: '/atualizar-perfil' }), []);

  return (
    <ContainerUserInfo>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 3
          }}
        >
          <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }} component="p">
            Olá, {name}!
          </Typography>
          <PersonalInformation
            icon={<EmailIcon sx={{ width: '16px', height: '16px' }} />}
            information={email}
          />
          <PersonalInformation
            icon={<WatchLaterIcon sx={{ width: '16px', height: '16px' }} />}
            information={`Por aqui desde ${date}`}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '2px'
            }}
          >
            <FlagIcon sx={{ width: '16px', height: '16px' }} />
            <Typography sx={{ fontSize: '12px', fontWeight: 'lighter' }} component="p">
              {stateName}
            </Typography>
            <LocationCityIcon sx={{ width: '16px', height: '16px' }} />
            <Typography sx={{ fontSize: '12px', fontWeight: 'lighter' }} component="p">
              {cityName}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1
          }}
        >
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            sx={{ cursor: 'pointer' }}
            onClick={onHandleGotoUpdateProfile}
            badgeContent={
              <Paper
                elevation={1}
                sx={{
                  display: 'flex',
                  borderRadius: '50%',
                  padding: '1px',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <EditRoundedIcon fontSize="small" />
              </Paper>
            }
          >
            <Avatar src={photo} imgProps={{ referrerPolicy: 'no-referrer' }} />
          </Badge>
        </Box>
      </Box>
    </ContainerUserInfo>
  );
};

interface IPersonalInformation {
  information: string;
  icon: React.ReactNode;
}

const PersonalInformation: React.FC<IPersonalInformation> = ({ information, icon }) => {
  return (
    <ContainerPersonalInformation>
      {icon}
      <Typography sx={{ fontSize: '12px', fontWeight: 'lighter' }} component="p">
        {information}
      </Typography>
    </ContainerPersonalInformation>
  );
};

import { FC, ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from 'store/storeHooks';

interface ProtectedRouteProps {
  children: ReactElement;
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const user = useAppSelector((e) => e.user.user);
  const location = useLocation();

  const search = () => {
    const params = new URLSearchParams(location.search);

    params.append('irPara', location.pathname.replace('/', ''));

    return params.toString();
  };

  if (!user)
    return (
      <Navigate
        to={{
          pathname: '/nao-autorizado',
          search: search()
        }}
      />
    );

  return children;
};

import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const CommentCardContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  padding: '16px 16px 0px 16px'
}));

export const CommentCardContent = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  flex: 1,
  padding: '0px 8px 0px 8px',
  gap: '3px'
}));

import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/storeHooks';
import { STATES_IN_BRAZIL } from 'utils/statesInBrazil';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { CloseButton } from 'components/CloseButton/CloseButton';

export const HeaderFeed = () => {
  const navigate = useNavigate();
  const citySelected = useAppSelector((e) => e.report.citySelected);

  const text = useMemo(() => {
    if (!citySelected) return '';

    const { name: cityName } = citySelected;

    const { name = '' } = STATES_IN_BRAZIL.find((e) => e.id === citySelected.uf) ?? {};

    return ` ${name} | ${cityName}`;
  }, [citySelected]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
      }}
    >
      <Box sx={{ display: 'flex', flex: 1 }} />
      <Box
        sx={{
          display: 'flex',
          flex: 4,
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Typography
          component="p"
          sx={{
            fontWeight: 'bold',
            fontSize: '0.7rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center'
          }}
        >
          <LocationOnIcon sx={{ height: '16px', width: '16px' }} />O que está acontecendo na minha
          cidade?
        </Typography>
        <Typography
          component="p"
          sx={{
            fontWeight: 'bold',
            fontSize: '0.8rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {text}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flex: 1, justifyContent: 'end' }}>
        <CloseButton action={() => navigate('/', { replace: true })} />
      </Box>
    </Box>
  );
};

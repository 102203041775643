import { IconButton } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

interface ICloseButton {
  action(): void;
}

export const CloseButton: React.FC<ICloseButton> = ({ action }) => {
  return (
    <IconButton onClick={action} aria-label="delete" size="small">
      <CloseIcon />
    </IconButton>
  );
};

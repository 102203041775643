import { styled } from '@mui/material/styles';
import { Typography, TypographyStyle } from '@mui/material';

export const TextHighlighted = styled(Typography)<TypographyStyle>(({ theme }) => ({
  fontSize: '0.8rem',
  display: 'flex',
  justifyContent: 'center',
  cursor: 'pointer',
  color: theme.palette.primary.light,
  fontWeight: 'bold'
}));

import { Box } from '@mui/material';
import { CardTypeProblem } from 'components/CardTypeProblem/CardTypeProblem';
import { ErrorTypography } from 'components/form/ErrorTypography';
import { ReportStepByStepContext } from 'pages/ReportStepByStep';
import { FC, useCallback, useMemo, useContext } from 'react';
import { IProblemSchema } from 'store/API/typeProblem/typeProblemApi';

interface ListProblemsProps {
  problems: IProblemSchema[];
}

export const ListProblems: FC<ListProblemsProps> = ({ problems }) => {
  const { formik } = useContext(ReportStepByStepContext)!;

  const error = useMemo(
    () => Boolean(formik.touched.typeProblem || Boolean(formik.errors.typeProblem)),
    [formik.touched.typeProblem, formik.errors.typeProblem]
  );

  const onHandleChange = useCallback((problem_id: string) => {
    formik.setFieldValue('typeProblem', problem_id);
  }, []);

  const valueSelected = useMemo(() => formik.values.typeProblem, [formik.values.typeProblem]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: '4px',
          maxHeight: '450px',
          overflowY: 'auto'
        }}
      >
        {problems.map((e) => (
          <CardTypeProblem
            onHandleChange={onHandleChange}
            isSelected={valueSelected === e._id}
            error={error}
            key={e._id}
            {...e}
          />
        ))}
      </Box>
      {error && <ErrorTypography>{formik.errors.typeProblem}</ErrorTypography>}
    </>
  );
};

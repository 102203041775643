import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { citiesApi } from './API/cities/citiesApi';
import { commentApi } from './API/comment/commentApi';
import { mapReducer } from './API/map/mapSlice';
import { reportApi } from './API/report/reportApi';
import { typeProblemApi } from './API/typeProblem/typeProblemApi';
import { userApi } from './API/user/userApi';
import userReducer from './API/user/userSlice';
import reportReduce from './API/report/reportSlice';

export function setUpStore() {
  const store = configureStore({
    reducer: {
      user: userReducer,
      map: mapReducer,
      report: reportReduce,
      [userApi.reducerPath]: userApi.reducer,
      [citiesApi.reducerPath]: citiesApi.reducer,
      [typeProblemApi.reducerPath]: typeProblemApi.reducer,
      [reportApi.reducerPath]: reportApi.reducer,
      [commentApi.reducerPath]: commentApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([
        userApi.middleware,
        citiesApi.middleware,
        typeProblemApi.middleware,
        reportApi.middleware,
        commentApi.middleware
      ])
  });

  setupListeners(store.dispatch);

  return store;
}

const store = setUpStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

import { Backdrop, CircularProgress } from '@mui/material';
import { useAppSelector } from 'store/storeHooks';

export const GlobalLoading = () => {
  const userLoading = useAppSelector((e) => e.user.loginLoading);

  return (
    <Backdrop sx={{ color: '#fff', zIndex: 1301 }} open={userLoading}>
      <CircularProgress color="inherit" />
      Carregando dados do usuário...
    </Backdrop>
  );
};

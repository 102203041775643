import { PageModal } from 'components/PageModal/PageModal';
import LoadingButton from '@mui/lab/LoadingButton';
import GoogleIcon from '@mui/icons-material/Google';
import { useCallback, useState } from 'react';
import { CustomDivider } from 'components/CustomDivider/CustomDivider';
import { Box, IconButton, InputAdornment, Typography } from '@mui/material';
import { TextTypeOfFormLoginPage } from './components/TextTypeOfFormLoginPage';
import { useLoginPage } from './useLoginPage.hook';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { GenericTextField } from 'components/GenericTextField/GenericTextField';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { TextHighlighted } from './components/TextTypeOfFormLoginPage.style';
import { useGoToPage } from 'routes/routes.util';

export interface ISchemaLogin {
  email: string;
  password: string;
}

const schemaLogin = Yup.object({
  email: Yup.string()
    .email('Email incompleto')
    .max(255)
    .required('É necessário informar um e-mail.'),
  password: Yup.string()
    .required('É necessário informar uma senha')
    .min(8, 'A senha deve conter pelo menos 8 letras')
}).defined();

export const LoginPage = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const goTo = useGoToPage();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    } as ISchemaLogin,
    validationSchema: schemaLogin,
    onSubmit: ({ email, password }) => onHandleSubmitLogin(email, password)
  });

  const { signingWithGoogle, loginWithEmail, registerWithEmail } = useLoginPage({ formik });

  const onHandleSubmitLogin = useCallback(
    (email: string, password: string) => {
      if (isLogin) {
        loginWithEmail(email, password);
      } else {
        registerWithEmail(email, password);
      }
    },
    [loginWithEmail, registerWithEmail, isLogin]
  );

  const onHandleShowPassword = useCallback(() => setShowPassword((e) => !e), []);

  const onHandleTextTypeOfForm = useCallback(() => {
    setIsLogin((e) => !e);
    formik.setFieldValue('password', '');
    setShowPassword(false);
  }, []);

  const onHandleCloseModel = useCallback(() => goTo({ to: '/menu' }), []);

  const onHandleResetPassword = useCallback(() => goTo({ to: '/resetPassword' }), []);

  const onHandleSubmitFormik = useCallback(() => formik.submitForm(), []);

  const textButtonAuthenticated = isLogin ? 'Entrar' : 'Criar conta';

  return (
    <PageModal
      modalProps={{
        open: true
      }}
      pageModalHeaderProps={{
        closeShadow: onHandleCloseModel,
        title: 'Olá, faça seu login ou cadastre-se'
      }}
      closeModalOnShadow={onHandleCloseModel}
      containerPageModalSx={{ maxWidth: 420 }}
    >
      <LoadingButton
        sx={{ height: '36px', width: '100%' }}
        onClick={signingWithGoogle}
        startIcon={<GoogleIcon />}
        variant="outlined"
      >
        Entrar com o Google
      </LoadingButton>
      <CustomDivider text="Ou" />
      <GenericTextField
        type={'email'}
        label="Email*"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          )
        }}
        id="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
      <GenericTextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={onHandleShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
        type={showPassword ? 'text' : 'password'}
        label="Senha*"
        id="password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
      />
      {isLogin && (
        <Box sx={{ display: 'flex', width: '100%' }}>
          <TextHighlighted onClick={onHandleResetPassword} sx={{ cursor: 'pointer' }}>
            Esqueceu?
          </TextHighlighted>
        </Box>
      )}
      <LoadingButton
        sx={{ height: '36px', width: '100%' }}
        onClick={onHandleSubmitFormik}
        variant="outlined"
      >
        {textButtonAuthenticated}
      </LoadingButton>
      <TextTypeOfFormLoginPage isLogin={isLogin} onHandleTextTypeOfForm={onHandleTextTypeOfForm} />
      <Typography
        sx={{ fontSize: '0.7rem', fontWeight: 'lighter', textAlign: 'center' }}
        component="p"
      >
        Ao continuar com o acesso, você concorda com a nossa política de privacidade
      </Typography>
    </PageModal>
  );
};

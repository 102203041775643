import { Alert, Box, Typography } from '@mui/material';
import { CloseButton } from 'components/CloseButton/CloseButton';
import { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import { IPhotoSelected } from './ModalUploadImage';
import {
  ContainerDrop,
  ThumbInner,
  Thumbs,
  ThumbsContainer,
  WarningSubText
} from './ModalUploadImage.style';

interface UploadImageProps {
  fileSelected: IPhotoSelected | null;
  setFileSelected: (e: IPhotoSelected | null) => void;
}

export const UploadImage: FC<UploadImageProps> = ({ fileSelected, setFileSelected }) => {
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: {
      'image/*': ['.png', '.jpg', '.jfif']
    },
    maxFiles: 1,
    onDrop: (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        setFileSelected({ file: file, preview: URL.createObjectURL(file) });
      } else {
        setFileSelected(null);
      }
    }
  });

  const onHandleRemoveImg = () => setFileSelected(null);

  return (
    <section className="container">
      <ContainerDrop {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <Typography
          sx={{ fontSize: '14px', fontWeight: 'lighter', textAlign: 'center' }}
          component="p"
        >
          Arraste e solte a imagem aqui ou clique para selecionar
        </Typography>
        <WarningSubText>(Apenas imagens *.jpeg, *jfif e *.png serão aceitas)</WarningSubText>
      </ContainerDrop>
      {fileSelected ? (
        <ThumbsContainer>
          <Thumbs>
            <ThumbInner>
              <img
                src={fileSelected.preview}
                style={{
                  display: 'block',
                  width: 'auto',
                  height: '100%'
                }}
              />
            </ThumbInner>
            <Box sx={{ position: 'absolute', right: 0 }}>
              <CloseButton action={onHandleRemoveImg} />
            </Box>
          </Thumbs>
        </ThumbsContainer>
      ) : null}
      {fileRejections.length > 0 ? (
        <Alert severity="error">Este arquivo ou imagem não é suportado! Tente outro.</Alert>
      ) : null}
    </section>
  );
};

import { Avatar, Box, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useGoToPage } from 'routes/routes.util';
import { useAppSelector } from 'store/storeHooks';

export const AvatarProfileHeader = () => {
  const user = useAppSelector((e) => e.user.user);
  const goTo = useGoToPage();
  const { name = '', photo = '' } = user ?? {};

  const onHandleOpenDrawer = useCallback(() => goTo({ to: '/menu' }), []);

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        justifyContent: 'end'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          cursor: 'pointer'
        }}
        onClick={onHandleOpenDrawer}
      >
        <Avatar
          src={photo}
          imgProps={{ referrerPolicy: 'no-referrer' }}
          sx={{ width: '30px', height: '30px' }}
        />
        <Typography sx={{ fontSize: '0.6rem', fontWeight: 'lighter' }} component="p">
          {name}
        </Typography>
      </Box>
    </Box>
  );
};

import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useUpdateCommentByIdMutation } from 'store/API/comment/commentApi';

interface useEditCommentProps {
  comment_id: string;
  comment: string;
  report_id: string;
}

export function useEditComment({ comment_id, comment, report_id }: useEditCommentProps) {
  const [updateComment, { isLoading: isLoadingUpdateComment }] = useUpdateCommentByIdMutation();
  const [openEditComment, setOpenEditComment] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onHandleOpenEditComment = useCallback(() => setOpenEditComment(true), []);
  const onHandleCloseEditComment = useCallback(() => setOpenEditComment(false), []);

  const onHandleUpdate = useCallback(
    async (newComment: string) => {
      try {
        await updateComment({ comment_id, report_id, comment: newComment }).unwrap();
        enqueueSnackbar('O comentário foi atualizado!', { variant: 'success' });
      } catch (e) {
        enqueueSnackbar('Não foi possível atualizar o comentário!', { variant: 'error' });
      }
      onHandleCloseEditComment();
    },
    [comment_id, report_id, onHandleCloseEditComment, comment]
  );

  return {
    openEditComment,
    onHandleOpenEditComment,
    isLoadingUpdateComment,
    onHandleCloseEditComment,
    onHandleUpdate
  };
}

import { BottomNavigation, BottomNavigationAction, useTheme } from '@mui/material';
import { useAppSelector } from 'store/storeHooks';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';

import AddIcon from '@mui/icons-material/Add';
import { useCallback } from 'react';
import { useGoToPage } from 'routes/routes.util';
import { BottomBarContainer, BottomBarPaper } from './BottomBarStyle';

const borderRadius = '20px';

export const BottomBar = () => {
  const showMap = useAppSelector((e) => e.map.showMap);
  const isUserAuthenticated = useAppSelector((e) => e.user.user);
  const theme = useTheme();

  const goTo = useGoToPage();

  const onHandleGoTo = useCallback(() => goTo({ to: '/cidade' }), []);

  const onHandleGoToFeed = useCallback(() => goTo({ to: '/feed' }), []);

  const onHandleGoPublish = useCallback(() => {
    if (isUserAuthenticated) {
      goTo({ to: '/publicar' });
    } else {
      goTo({ to: '/login', params: `?irPara=publicar` });
    }
  }, [isUserAuthenticated]);

  if (showMap) {
    return null;
  }

  return (
    <BottomBarContainer>
      <BottomBarPaper elevation={3}>
        <BottomNavigation
          sx={{
            width: '100%',
            borderTopLeftRadius: borderRadius,
            borderTopRightRadius: borderRadius,
            backgroundColor: theme.palette.primary.light
          }}
          showLabels
        >
          <BottomNavigationAction
            onClick={onHandleGoToFeed}
            sx={{ color: theme.palette.primary.contrastText }}
            label="Publicações"
            icon={<DynamicFeedIcon sx={{ color: theme.palette.primary.contrastText }} />}
          />
          <BottomNavigationAction
            onClick={onHandleGoPublish}
            sx={{ color: theme.palette.primary.contrastText }}
            label="Publicar"
            icon={<AddIcon color="inherit" sx={{ color: theme.palette.primary.contrastText }} />}
          />
          <BottomNavigationAction
            onClick={onHandleGoTo}
            sx={{ color: theme.palette.primary.contrastText }}
            label="Ir Para"
            icon={<LocationOnIcon sx={{ color: theme.palette.primary.contrastText }} />}
          />
        </BottomNavigation>
      </BottomBarPaper>
    </BottomBarContainer>
  );
};

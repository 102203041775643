import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, IResponse } from '../api.util';
import { IReport } from './reportInterfaces';

export interface IGetReportsByLatLng {
  lat: number;
  lng: number;
}

export const REPORTS_TO_FEED_SKIP = 4;

export const reportApi = createApi({
  reducerPath: 'reportApi',
  baseQuery: baseQuery,
  tagTypes: ['myReports', 'reportById'],
  endpoints: (builder) => ({
    publishReport: builder.mutation<IResponse<IReport>, FormData>({
      query: (data) => ({
        method: 'POST',
        url: 'report',
        body: data
      })
    }),
    getReportById: builder.query<IReport, string>({
      query: (data) => ({
        method: 'GET',
        url: `report?id=${data}`
      }),
      transformResponse: (response: IResponse<IReport>) => {
        return response.data;
      },
      providesTags: (_, error, report_id) => (error ? [] : [{ type: 'reportById', id: report_id }])
    }),
    getReportsByLatLng: builder.mutation<IReport[], IGetReportsByLatLng>({
      query: (data) => ({
        method: 'GET',
        url: `report/location?lat=${data.lat}&lng=${data.lng}`
      }),
      transformResponse: (reports: IResponse<IReport[]>) => reports.data
    }),
    getReportsToFeed: builder.mutation<IReport[], { city_id: string; skip: number }>({
      query: ({ city_id, skip }) => ({
        method: 'GET',
        url: `report/feed?city_id=${city_id}&skip=${skip}&pageSize=${REPORTS_TO_FEED_SKIP}`
      }),
      transformResponse: (response: IResponse<IReport[]>) => {
        return response.status === 'ok' ? response.data : [];
      }
    }),
    getReportsByUserId: builder.query<IReport[], void>({
      query: () => ({
        url: `report/reports`,
        method: 'GET'
      }),
      transformResponse: (response: IResponse<IReport[]>) => response.data,
      providesTags: [{ type: 'myReports' }]
    }),
    deleteReport: builder.mutation<void, string>({
      query: (report_id: string) => ({
        url: `report/delete`,
        method: 'POST',
        body: { report_id }
      }),
      invalidatesTags: [{ type: 'myReports' }]
    }),
    updateReport: builder.mutation<IResponse<IReport>, FormData>({
      query: (data) => ({
        method: 'PUT',
        url: 'report',
        body: data
      }),
      invalidatesTags: [{ type: 'myReports' }]
    })
  })
});

export const fixedCacheKeyReportsByLatLng = 'ByLatLng';

export const {
  usePublishReportMutation,
  useGetReportByIdQuery,
  useGetReportsByLatLngMutation,
  useGetReportsToFeedMutation,
  useGetReportsByUserIdQuery,
  useDeleteReportMutation,
  useUpdateReportMutation
} = reportApi;

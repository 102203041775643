import { Box, Typography } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import { CloseButton } from 'components/CloseButton/CloseButton';
import { FC } from 'react';

interface CardPlaceSelectedProps {
  action: () => void;
}

export const CardPlaceSelected: FC<CardPlaceSelectedProps> = ({ action }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        borderWidth: '0.5px',
        borderStyle: 'solid',
        borderColor: '#D9D9D9',
        borderRadius: '0.5rem',
        padding: '0.5rem',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <PlaceIcon />
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          width: '100%',
          flexDirection: 'column'
        }}
      >
        <Typography sx={{ fontSize: '0.7rem', fontWeight: 'bold' }} component="p">
          Você selecionou o local pelo mapa!
        </Typography>
        <Typography sx={{ fontSize: '0.8rem', fontWeight: 'lighter' }} component="p">
          Não se preocupe! O endereço será adicionado automaticamente.
        </Typography>
      </Box>
      <CloseButton action={action} />
    </Box>
  );
};

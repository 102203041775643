import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IResponse } from '../api.util';

export interface ISubProblemReport {
  description: string;
  type: string;
  _id: string;
}

export interface IProblemSchema {
  _id: string;
  description: string;
  title: string;
  type: string;
  subTypeReport: ISubProblemReport[];
}

export const typeProblemApi = createApi({
  reducerPath: 'typeProblemApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_URL
  }),
  tagTypes: ['getAllProblem'],
  endpoints: (builder) => ({
    getAllTypeReport: builder.query<IProblemSchema[], void>({
      query: () => ({
        method: 'GET',
        url: 'typeReport'
      }),
      providesTags: [{ type: 'getAllProblem' }],
      transformResponse: (response: IResponse<IProblemSchema[]>) => response.data
    })
  })
});

export const { useGetAllTypeReportQuery } = typeProblemApi;

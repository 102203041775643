import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup
} from 'firebase/auth';
import { auth } from 'firebase-config';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { FormikProps } from 'formik';
import { ISchemaLogin } from './LoginPage';
import { useAppDispatch } from 'store/storeHooks';
import { setLoginLoading } from 'store/API/user/userSlice';

interface UseLoginPageProps {
  formik: FormikProps<ISchemaLogin>;
}

export function useLoginPage({ formik }: UseLoginPageProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const signingWithGoogle = useCallback(async () => {
    try {
      const provider = new GoogleAuthProvider();

      await signInWithPopup(auth, provider);

      dispatch(setLoginLoading({ loading: true }));

      enqueueSnackbar('Autenticado com sucesso!', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Não foi possível concluir o login!', { variant: 'error' });
    }
  }, []);

  const registerWithEmail = useCallback(async (email: string, password: string) => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);

      dispatch(setLoginLoading({ loading: true }));

      enqueueSnackbar('Conta criada com sucesso!', { variant: 'success' });
    } catch (error: any) {
      const errorCode = error.code;
      if (errorCode === 'auth/email-already-in-use') {
        formik.setErrors({
          email: 'Email já em uso'
        });
      }
    }
  }, []);

  const loginWithEmail = useCallback(async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);

      dispatch(setLoginLoading({ loading: true }));

      enqueueSnackbar('Autenticado com sucesso!', { variant: 'success' });
    } catch (e) {
      formik.setErrors({
        email: 'Verifique seu e-mail',
        password: 'Verifique sua senha'
      });
    }
  }, []);

  return { signingWithGoogle, registerWithEmail, loginWithEmail };
}

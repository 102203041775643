import { styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

export const BackgroundPageModal = styled(Box)<BoxProps>(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  overflowY: 'auto',
  justifyContent: 'center',
  position: 'absolute',
  alignItems: 'center',
  paddingTop: '5px',
  paddingBottom: '5px'
}));

export const ContainerPageModal = styled(Box)<BoxProps>(({ theme }) => ({
  zIndex: '1100',
  isolation: 'isolate',
  maxWidth: 480,
  width: '90%',
  display: 'flex',
  height: 'fit-content',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'center',
  border: `1px solid ${theme.palette.primary}`,
  borderRadius: '32px',
  padding: '16px 16px',
  outline: 'none',
  backgroundColor: '#F5F5F5',
  gap: '8px',
  margin: 'auto'
}));

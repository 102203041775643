import { styled } from '@mui/material/styles';
import { Paper, PaperProps, Box, BoxProps } from '@mui/material';

const borderRadius = '20px';

export const BottomBarPaper = styled(Paper)<PaperProps>(({ style }) => ({
  width: '100%',
  maxWidth: '500px',
  borderTopLeftRadius: borderRadius,
  borderTopRightRadius: borderRadius
}));

export const BottomBarContainer = styled(Box)<BoxProps>(({ style }) => ({
  position: 'absolute',
  bottom: 0,
  display: 'flex',
  zIndex: 1100,
  width: '100%',
  justifyContent: 'center'
}));

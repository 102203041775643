import { CustomDivider } from 'components/CustomDivider/CustomDivider';
import { GenericTextField } from 'components/GenericTextField/GenericTextField';
import { IPhotoSelected } from 'components/UploadImage/ModalUploadImage';
import { TextArea } from 'components/TextArea/TextArea';
import { FC, useCallback, useContext, useState } from 'react';
import { ReportStepByStepContext } from '.';
import { AddressForm } from './AddressForm';
import { ReportFormFooter } from './components/ReportFormFooter';
import { UploadImage } from 'components/UploadImage/UploadImage';
import { CardCheckAnonymous } from 'components/CardCheckAnonymous/CardCheckAnonymous';
import { DropDownProblem } from './components/DropDownProblem/DropDownProblem';
import {
  fixedCacheKeyReportsByLatLng,
  reportApi,
  useGetReportsByLatLngMutation,
  usePublishReportMutation
} from 'store/API/report/reportApi';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { useAppDispatch } from 'store/storeHooks';
import { useMap } from 'react-leaflet';

interface ReportFormProps {
  back: () => void;
}

export const ReportForm: FC<ReportFormProps> = React.memo(({ back }) => {
  const { formik } = useContext(ReportStepByStepContext)!;
  const [imageSelected, setImageSelected] = useState<IPhotoSelected | null>(null);
  const [publish, { isLoading }] = usePublishReportMutation();

  const [getReports] = useGetReportsByLatLngMutation({
    fixedCacheKey: fixedCacheKeyReportsByLatLng
  });

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const map = useMap();

  const { enqueueSnackbar } = useSnackbar();

  const onHandleAnonymous = useCallback(
    () => formik.setFieldValue('anonymous', !formik.values.anonymous),
    [formik.values.anonymous]
  );

  const onHangleGoMap = useCallback((lat: number, lng: number) => {
    map.flyTo([lat, lng], 18);
  }, []);

  const onHandleSubmit = useCallback(async () => {
    formik.handleSubmit();

    const error = await formik.validateForm();

    if (Object.values(error).length < 1 && formik.isValid) {
      const formData = new FormData();

      for (const [key, value] of Object.entries(formik.values)) {
        if (key !== 'form' && key !== 'location') {
          formData.append(key, value);
        }

        if (key === 'location' && value) {
          formData.append(key, value);
        }

        if (key === 'form' && value && !formik.values.location) {
          Object.entries(formik.values.form ?? {}).forEach(([formKey, formValue]) => {
            if (formValue && formKey !== 'state') {
              formData.append(formKey, formValue as string);
            }
          });
        }
      }

      if (imageSelected && imageSelected.file) {
        formData.append('photoFile', imageSelected.file);
      }

      try {
        const response = await publish(formData).unwrap();

        if (response.status && response.status === 'ok') {
          navigate(`/post/${response.data._id}`);
          enqueueSnackbar('Publicado!', { variant: 'success' });

          const [lat, lng] = response.data.location.coordinates;

          onHangleGoMap(lat, lng);

          dispatch(reportApi.util.invalidateTags(['myReports']));

          getReports({ lat, lng });
        }
      } catch (e: any) {
        const message =
          e && e.data && e.data.message
            ? e.data.message
            : 'Não foi possível publicar! Tente de novamente.';
        enqueueSnackbar(message, { variant: 'error' });
      }
    }
  }, [formik, imageSelected]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{
        display: 'flex',
        gap: '0.8rem',
        flexDirection: 'column',
        width: '100%'
      }}
    >
      <AddressForm formik={formik} />
      <CustomDivider text="Publicação" />
      <DropDownProblem formik={formik} />
      <GenericTextField
        value={formik.values.title}
        onChange={formik.handleChange}
        error={formik.touched.title && Boolean(formik.errors.title)}
        helperText={formik.touched.title && formik.errors.title}
        id="title"
        name="title"
        label="Título"
        variant="outlined"
      />
      <TextArea
        value={formik.values.description}
        onChange={formik.handleChange}
        error={Boolean(formik.touched.description && Boolean(formik.errors.description))}
        messageError={formik.errors.description}
        id="description"
        name="description"
        text="Descreva seu problema"
      />
      <UploadImage fileSelected={imageSelected} setFileSelected={setImageSelected} />
      <CardCheckAnonymous value={formik.values.anonymous} onHandleAnonymous={onHandleAnonymous} />
      <ReportFormFooter
        isLoading={formik.isValidating || isLoading}
        back={back}
        go={onHandleSubmit}
      />
    </form>
  );
});

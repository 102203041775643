import { styled } from '@mui/material/styles';
import { Box, BoxProps, Drawer, DrawerProps } from '@mui/material';
import { paddingDrawer } from '../../MenuDrawer.style';

export const ContainerUserInfo = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: paddingDrawer
}));

export const ContainerPersonalInformation = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '2px'
}));

import { Box, Radio, Typography } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { IProblemSchema } from 'store/API/typeProblem/typeProblemApi';
import { getIcon } from 'utils/icons';
import { ContainerCardTypeProblem } from './CardTypeProblem.style';

interface CardTypeProblemProps extends IProblemSchema {
  error: boolean;
  isSelected: boolean;
  onHandleChange: (problem_id: string) => void;
}

export const CardTypeProblem: FC<CardTypeProblemProps> = ({
  _id,
  description,
  error,
  title,
  type,
  isSelected,
  onHandleChange
}) => {
  const handleChange = useCallback(() => {
    onHandleChange(_id);
  }, [_id]);

  const controlProps = useMemo(
    () => ({
      checked: isSelected,
      onChange: handleChange,
      value: _id,
      name: 'color-radio-button-demo',
      inputProps: { 'aria-label': title }
    }),
    [isSelected]
  );

  const cardColor = useMemo(() => {
    if (isSelected) {
      return 'theme';
    }

    if (error) {
      return 'red';
    }

    return '#D9D9D9';
  }, [isSelected, error]);

  const icon = useMemo(() => getIcon({ type }), [type]);

  return (
    <ContainerCardTypeProblem cardColor={cardColor} onClick={handleChange}>
      {icon}
      <Radio {...controlProps} color="secondary" />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column'
        }}
      >
        <Typography sx={{ fontSize: '0.9rem', fontWeight: 'bold' }} component="p">
          {title}
        </Typography>
        <Typography sx={{ fontSize: '0.8rem', fontWeight: 'lighter' }} component="p">
          {description}
        </Typography>
      </Box>
    </ContainerCardTypeProblem>
  );
};

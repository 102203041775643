import { Box, Button, Typography } from '@mui/material';
import AutoCompleteInput from 'components/AutoCompleteInput/AutoCompleteInput';
import { GenericTextField } from 'components/GenericTextField/GenericTextField';
import { useSelectCity } from 'hooks/useSelectCity.hook';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useGoToPage, useQuery } from 'routes/routes.util';
import { CardPlaceSelected } from './components/CardPlaceSelected';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import { useAppDispatch } from 'store/storeHooks';
import { setShowMap } from 'store/API/map/mapSlice';
import { CustomDivider } from 'components/CustomDivider/CustomDivider';
import { ISchemaReportForm } from './useReportForm';
import { FormikProps } from 'formik';
import { ICity } from 'store/API/cities/citiesApi';

interface AddressFormProps {
  formik: FormikProps<ISchemaReportForm>;
  initialCity?: ICity | null;
  hideButtonGoToMap?: boolean;
}

export const AddressForm: FC<AddressFormProps> = ({
  formik,
  initialCity = null,
  hideButtonGoToMap = false
}) => {
  const query = useQuery();
  const goTo = useGoToPage();
  const dispatch = useAppDispatch();

  const onHandleShowMap = useCallback(() => dispatch(setShowMap({ action: true })), []);

  const isLocationSelectedByMap = useMemo(
    () => Boolean(formik.values.location),
    [formik.values.location]
  );

  const onHandleRemoveLocation = useCallback(() => {
    goTo({ to: '/publicar' });
    formik.setFieldValue('form', {});
    formik.setFieldValue('location', null);
  }, [query]);

  const {
    states,
    stateSelected,
    onHandleState,
    cities,
    citySelected,
    onHandleCity,
    isCityLoading
  } = useSelectCity({
    formikValidateCity: formik.setFieldValue,
    formikValidateState: formik.setFieldValue,
    initialCity: initialCity,
    initialState: initialCity ? initialCity.uf : undefined,
    validateCityField: 'form.city',
    validateStateField: 'form.state'
  });

  useEffect(() => {
    const latLng = query.get('latLng') ?? '';

    if (latLng && latLng.split('|').length > 1) {
      const [lat, lng] = latLng.split('|');
      formik.setFieldValue('location', `${lat},${lng}`);
      formik.setFieldValue('form', null);
      onHandleState(null, null);
    } else {
      formik.setFieldValue('location', null);
    }
  }, [query]);

  if (isLocationSelectedByMap) {
    return <CardPlaceSelected action={onHandleRemoveLocation} />;
  }

  return (
    <>
      {!hideButtonGoToMap ? (
        <>
          <Typography
            sx={{
              fontSize: '0.8rem',
              fontWeight: 'bold',
              display: 'flex',
              width: '100%',
              justifyContent: 'center'
            }}
            component="div"
          >
            Selecione o endereço através do mapa
          </Typography>
          <Button onClick={onHandleShowMap} startIcon={<AddLocationAltIcon />} variant="outlined">
            IR PARA O MAPA
          </Button>
          <CustomDivider text="Ou" />
        </>
      ) : null}
      <Box sx={{ display: 'flex', flex: 1, minWidth: '200px', gap: '8px' }}>
        <AutoCompleteInput
          options={states}
          id="form.state"
          label="Selecione o estado"
          valueSelected={stateSelected}
          onChange={onHandleState}
          error={formik.touched.form?.state && Boolean(formik.errors.form?.state)}
          helperText={formik.touched.form?.state && formik.errors.form?.state}
        />
        <AutoCompleteInput
          id="form.city"
          isLoading={isCityLoading}
          options={cities}
          isDisabled={isCityLoading || cities.length < 1}
          label="Selecione a cidade"
          valueSelected={citySelected}
          onChange={onHandleCity}
          error={Boolean(formik.errors.form?.city)}
          helperText={formik.errors.form?.city}
        />
      </Box>
      <GenericTextField
        value={formik.values.form.district}
        onChange={formik.handleChange}
        error={formik.touched.form?.district && Boolean(formik.errors.form?.district)}
        helperText={formik.touched.form?.district && formik.errors.form?.district}
        id="form.district"
        name="form.district"
        label="Bairro"
        variant="outlined"
      />
      <GenericTextField
        value={formik.values.form.address}
        onChange={formik.handleChange}
        error={formik.touched.form?.address && Boolean(formik.errors.form?.address)}
        helperText={formik.touched.form?.address && formik.errors.form?.address}
        id="form.address"
        name="form.address"
        label="Endereço"
        variant="outlined"
      />
    </>
  );
};

import { IPhotoSelected } from 'components/UploadImage/ModalUploadImage';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useCreateUserMutation } from 'store/API/user/userApi';
import { useLocation, useNavigate } from 'react-router-dom';

export interface ISchemaCreateUser {
  name: string;
  city: null | string;
  state: null | number;
}

export function useCreateProfile() {
  const [createUser, { isLoading: isLoadingUserUpdate }] = useCreateUserMutation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();

  const onHandleCreateUser = useCallback(
    async (data: ISchemaCreateUser, file: IPhotoSelected | null) => {
      let formData = new FormData();

      if (file && file.file) {
        formData.append('photoFile', file.file);
      }

      if (file && !file.file && file.preview) {
        formData.append('photo', file.preview);
      }

      for (const [key, value] of Object.entries(data)) {
        if (key !== 'state') {
          formData.append(key, value);
        }
      }

      try {
        await createUser(formData).unwrap();

        const urlParams = new URLSearchParams(location.search);
        const path = urlParams.get('irPara') ?? '';

        let url = '/' + path;

        if (path) {
          urlParams.delete('irPara');

          if (urlParams.values.length) {
            url += '?' + urlParams.toString();
          }
        }

        navigate(url);

        enqueueSnackbar('Usuário atualizado com sucesso!', {
          variant: 'success'
        });
      } catch (e) {
        enqueueSnackbar('Não foi possível atualizar seu perfil! Tente novamente.', {
          variant: 'error'
        });
      }
    },
    []
  );

  return { onHandleCreateUser, isLoadingUserUpdate };
}

import { CardCheckAnonymous } from 'components/CardCheckAnonymous/CardCheckAnonymous';
import { CustomDivider } from 'components/CustomDivider/CustomDivider';
import { GenericTextField } from 'components/GenericTextField/GenericTextField';
import { PageModal } from 'components/PageModal/PageModal';
import { TextArea } from 'components/TextArea/TextArea';
import { IPhotoSelected } from 'components/UploadImage/ModalUploadImage';
import { UploadImage } from 'components/UploadImage/UploadImage';
import { useSnackbar } from 'notistack';
import { AddressForm } from 'pages/ReportStepByStep/AddressForm';
import { DropDownProblem } from 'pages/ReportStepByStep/components/DropDownProblem/DropDownProblem';
import { ReportFormFooter } from 'pages/ReportStepByStep/components/ReportFormFooter';
import { ISchemaReportForm, useReportForm } from 'pages/ReportStepByStep/useReportForm';
import { FC, useCallback, useMemo, useState } from 'react';
import { useGoToPage } from 'routes/routes.util';
import { useUpdateReportMutation } from 'store/API/report/reportApi';
import { IReport } from 'store/API/report/reportInterfaces';
import _ from 'lodash';

interface ModalEditMyReportProps {
  open: boolean;
  closeModal: () => void;
  report: IReport;
}

export const ModalEditMyReport: FC<ModalEditMyReportProps> = ({ open, closeModal, report }) => {
  const [imageSelected, setImageSelected] = useState<IPhotoSelected | null>(() =>
    report.photo ? { preview: report.photo } : null
  );

  const [updateReport, { isLoading }] = useUpdateReportMutation();

  const goTo = useGoToPage();

  const { enqueueSnackbar } = useSnackbar();

  const city = useMemo(() => report.city ?? null, [report]);

  const initialValues: ISchemaReportForm = useMemo(
    () => ({
      date: report.date,
      anonymous: report.anonymous,
      description: report.description,
      title: report.title,
      subTypeProblem: report.subTypeProblem._id,
      typeProblem: report.typeProblem._id,
      form: city
        ? {
            city: city._id,
            state: city.uf,
            address: report.address ?? '',
            district: report.district ?? ''
          }
        : {},
      location: undefined
    }),
    [report]
  );

  const { formik } = useReportForm({
    initialValues: initialValues
  });

  const onHandleAnonymous = useCallback(
    () => formik.setFieldValue('anonymous', !formik.values.anonymous),
    [formik.values.anonymous]
  );

  const onHandleSubmit = useCallback(async () => {
    formik.handleSubmit();

    const error = await formik.validateForm();

    const shouldUpdate = _.isEqual(formik.values, initialValues);

    if (Object.values(error).length < 1 && formik.isValid && !shouldUpdate) {
      const formData = new FormData();

      formData.append('_id', report._id);

      for (const [key, value] of Object.entries(formik.values)) {
        if (key !== 'form' && key !== 'location') {
          formData.append(key, value);
        }

        if (key === 'form' && value && !formik.values.location) {
          Object.entries(formik.values.form ?? {}).forEach(([formKey, formValue]) => {
            if (formValue && formKey !== 'state') {
              formData.append(formKey, formValue as string);
            }
          });
        }
      }

      if (imageSelected && imageSelected.file) {
        formData.append('photoFile', imageSelected.file);
      }

      if (imageSelected && !imageSelected.file && imageSelected.preview) {
        formData.append('photo', imageSelected.preview);
      }

      try {
        const response = await updateReport(formData).unwrap();

        if (response.status && response.status === 'ok') {
          goTo({ to: '/minhas-postagens' });
          enqueueSnackbar('Publicado!', { variant: 'success' });
        }
      } catch (e: any) {
        const message =
          e && e.data && e.data.message
            ? e.data.message
            : 'Não foi possível atualizar! Tente de novamente.';
        enqueueSnackbar(message, { variant: 'error' });
      }
    }
  }, [formik, imageSelected]);

  return (
    <PageModal
      modalProps={{ open: open }}
      pageModalHeaderProps={{ title: 'Editar postagem', closeShadow: closeModal }}
    >
      <form
        style={{
          display: 'flex',
          gap: '12px',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <AddressForm hideButtonGoToMap={true} initialCity={city} formik={formik} />
        <CustomDivider text="Publicação" />
        <DropDownProblem formik={formik} />
        <GenericTextField
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
          id="title"
          name="title"
          label="Título"
          variant="outlined"
        />
        <TextArea
          value={formik.values.description}
          onChange={formik.handleChange}
          error={Boolean(formik.touched.description && Boolean(formik.errors.description))}
          messageError={formik.errors.description}
          id="description"
          name="description"
          text="Descreva seu problema"
        />
        <UploadImage fileSelected={imageSelected} setFileSelected={setImageSelected} />
        <CardCheckAnonymous value={formik.values.anonymous} onHandleAnonymous={onHandleAnonymous} />
        <ReportFormFooter
          isLoading={formik.isValidating || isLoading}
          back={closeModal}
          go={onHandleSubmit}
          textGoButton={'Salvar'}
          textBack={'Cancelar'}
        />
      </form>
    </PageModal>
  );
};

import { Box, SxProps, Theme, Typography } from '@mui/material';
import React, { FC } from 'react';
import { CloseButton } from '../../CloseButton/CloseButton';
import {
  ContainerPageModalHeader,
  ContainerTitlePageModalHeader,
  TitlePageModalHeader
} from './PageModalHeader.style';

export interface PageModalHeaderProps {
  closeShadow?: () => void;
  title: string;
  titleHeaderSx?: SxProps<Theme>;
}

export const PageModalHeader: FC<PageModalHeaderProps> = ({
  closeShadow,
  title,
  titleHeaderSx = {}
}) => {
  return (
    <ContainerPageModalHeader>
      <Box sx={{ display: 'flex', flex: 1 }} />
      <ContainerTitlePageModalHeader>
        <TitlePageModalHeader
          sx={{
            ...titleHeaderSx
          }}
        >
          {title}
        </TitlePageModalHeader>
      </ContainerTitlePageModalHeader>
      <Box sx={{ display: 'flex', flex: 1, justifyContent: 'end' }}>
        {Boolean(closeShadow) && <CloseButton action={closeShadow!} />}
      </Box>
    </ContainerPageModalHeader>
  );
};

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { ICity } from 'store/API/cities/citiesApi';

export interface ISchemaReportForm {
  anonymous: boolean;
  date: string;
  description: string;
  title: string;
  subTypeProblem: string;
  typeProblem: string;
  location?: string;
  form: {
    city?: string | null;
    state?: number | null;
    address?: string;
    district?: string;
    placeName?: string;
  };
}

const fieldRequired = {
  state: Yup.number().required('Selecione o estado').typeError('Selecione o estado').nullable(true),
  city: Yup.string().when(['state'], {
    is: (state: number | null) => {
      return !Boolean(state);
    },
    then: Yup.string().required('Selecione o estado primeiro').nullable(),
    otherwise: Yup.string().required('Selecione a cidade').nullable()
  }),
  address: Yup.string(),
  district: Yup.string()
};

const schemaReportForm = Yup.object().shape(
  {
    title: Yup.string()
      .required('O título é obrigatório!')
      .min(3, 'O mínimo é de 3 letras')
      .max(180, 'O máximo é 180 letras.'),
    anonymous: Yup.boolean().default(false),
    description: Yup.string()
      .required('A descrição é obrigatória!')
      .min(3, 'O mínimo é de 3 letras')
      .max(400, 'O máximo é 400 letras'),
    date: Yup.string().default(undefined),
    typeProblem: Yup.string().required('Você precisa selecionar um tipo de problema'),
    subTypeProblem: Yup.string().required('Você precisa especificar o tipo de problema'),
    location: Yup.string()
      .when('form', {
        is: (form: any) => !form,
        then: Yup.string()
          .required('Você precisa selecionar um local através do mapa ou formulário')
          .nullable()
      })
      .nullable(),
    form: Yup.object()
      .when('location', {
        is: (location: string) => !location,
        then: Yup.object(fieldRequired).required(
          'Você precisa selecionar um local através do mapa ou formulário'
        ),
        otherwise: Yup.object().nullable()
      })
      .nullable()
  },
  [
    ['location', 'form'],
    ['form', 'location']
  ]
);

interface UseReportFormProps {
  initialValues?: ISchemaReportForm;
}

const auxInitialValues = {
  anonymous: false,
  description: '',
  subTypeProblem: '',
  title: '',
  typeProblem: '',
  form: {}
} as ISchemaReportForm;

export function useReportForm<S = undefined>(props?: UseReportFormProps | S) {
  const { initialValues = auxInitialValues } = props ?? {};

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schemaReportForm,
    onSubmit: (values) => {}
  });

  return { formik };
}

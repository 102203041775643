import { Avatar, Box, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { IUser } from 'store/API/user/userApi';
import { formatDate } from 'utils/utils.hooks';

interface ReportCardUserInfoProps {
  user: IUser | null;
  createdAt: string;
}

export const ReportCardUserInfo: FC<ReportCardUserInfoProps> = ({ user, createdAt }) => {
  const { name = '', photo, _id } = user ?? {};

  const date = formatDate(createdAt, 'DD/MM/YYYY HH:mm:ss');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        gap: '0.5rem'
      }}
    >
      <Avatar src={photo} imgProps={{ referrerPolicy: 'no-referrer' }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'start'
        }}
      >
        <Typography sx={{ fontSize: '0.7rem', fontWeight: 'lighter' }} component="p">
          {user ? name : 'Anônimo'}
        </Typography>
        <Typography sx={{ fontSize: '0.7rem', fontWeight: 'lighter' }} component="p">
          {date}
        </Typography>
      </Box>
    </Box>
  );
};

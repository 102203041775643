import { Box, Typography } from '@mui/material';
import { CloseButton } from 'components/CloseButton/CloseButton';
import { CustomDivider } from 'components/CustomDivider/CustomDivider';
import { CSSProperties, FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { useGoToPage } from 'routes/routes.util';
import { IReport } from 'store/API/report/reportInterfaces';
import { getIcon } from 'utils/icons';
import { CommentSection } from './comment/CommentSection';
import { ReportCardFooter } from './components/ReportCardFooter';
import { ReportCardUserInfo } from './components/ReportCardUserInfo';
import {
  ContainerReportCard,
  ReportCardIconProblemContainer,
  ReportCardTitle
} from './ReportCard.style';

interface IReportCard {
  hiddenCloseButton?: boolean;
  report: IReport;
  headerButton?: ReactNode;
  reportCardStyle?: CSSProperties;
}

export const ReportCard: FC<IReportCard> = ({
  report,
  hiddenCloseButton = false,
  headerButton,
  reportCardStyle
}) => {
  const {
    title,
    formatted_address,
    typeProblem: { type, title: descriptionTypeProblem },
    createdAt,
    user,
    description,
    photo,
    _id: report_id
  } = report;

  const [openComment, setOpenComment] = useState(false);

  const onHandleOpenComment = useCallback(() => {
    setOpenComment((e) => !e);
  }, []);

  const goTo = useGoToPage();

  const button = useCallback(() => {
    if (hiddenCloseButton) return undefined;

    if (headerButton) return headerButton;

    return <CloseButton action={() => goTo({ to: '/' })} />;
  }, [hiddenCloseButton, headerButton]);

  const icon = useMemo(() => getIcon({ type, fontSize: 'small' }), [type]);

  return (
    <ContainerReportCard style={reportCardStyle}>
      <ReportCardTitle>
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }} component="p">
          {title}
        </Typography>
        {button()}
      </ReportCardTitle>
      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <Typography sx={{ fontSize: '0.7rem', fontWeight: 'bold' }} component="p">
          {formatted_address}
        </Typography>
      </Box>
      <CustomDivider />
      <ReportCardIconProblemContainer>
        {icon}
        <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold' }} component="p">
          {descriptionTypeProblem}
        </Typography>
      </ReportCardIconProblemContainer>
      <ReportCardUserInfo user={user} createdAt={createdAt} />
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Typography
          component="p"
          sx={{
            fontSize: '0.8rem',
            fontWeight: 'lighter',
            textAlign: 'justify',
            lineBreak: 'anywhere'
          }}
        >
          {description}
        </Typography>
      </Box>
      {photo && (
        <Box sx={{ height: '300px', width: '100%' }}>
          <img
            referrerPolicy="no-referrer"
            width={'100%'}
            height={'100%'}
            style={{ objectFit: 'contain' }}
            src={photo}
          />
        </Box>
      )}
      <CustomDivider />
      <ReportCardFooter report={report} onHandleOpenComment={onHandleOpenComment} />
      {openComment && <CommentSection report_id={report_id} />}
    </ContainerReportCard>
  );
};

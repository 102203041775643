import React, { FC, useCallback } from 'react';
import { CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useAppSelector } from 'store/storeHooks';
import { useGoToPage } from 'routes/routes.util';

interface CommentInputProps {
  initialComment?: string;
  onHandleSubmitComment: (e: string) => void;
  isLoading?: boolean;
  report_id?: string;
}

const schemaAddComment = Yup.object({
  comment: Yup.string()
    .min(5, 'O mínimo é de 5 letras')
    .max(400, 'O máximo é 400 letras.')
    .required('O campo é obrigatório.')
}).defined();

export const CommentInput: FC<CommentInputProps> = ({
  initialComment = '',
  onHandleSubmitComment,
  isLoading,
  report_id
}) => {
  const goTo = useGoToPage();

  const user = useAppSelector((e) => e.user.user);

  const formik = useFormik({
    initialValues: {
      comment: initialComment
    },
    validationSchema: schemaAddComment,
    onSubmit: ({ comment }) => {
      onHandleSubmitComment(comment);
    }
  });

  const onHandleUserAuth = useCallback(() => {
    if (!user) {
      goTo({ to: '/login', params: `?irPara=post/${report_id}` });
    }
  }, [user, goTo, report_id]);

  return (
    <TextField
      onClick={onHandleUserAuth}
      placeholder="Adicionar comentário"
      id="comment"
      fullWidth={true}
      inputProps={{ maxLength: 400 }}
      value={formik.values.comment}
      onChange={formik.handleChange}
      error={formik.touched.comment && Boolean(formik.errors.comment)}
      helperText={formik.touched.comment && formik.errors.comment}
      InputProps={{
        sx: { fontSize: '14px' },
        multiline: true,
        endAdornment: isLoading ? (
          <InputAdornment disablePointerEvents={true} position="start">
            <CircularProgress size={24} />
          </InputAdornment>
        ) : (
          <InputAdornment onClick={formik.submitForm} position="start">
            <IconButton>
              <SendIcon />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

import { Box, Typography } from '@mui/material';
import AutoCompleteInput from 'components/AutoCompleteInput/AutoCompleteInput';
import { PageModal } from 'components/PageModal/PageModal';
import { useSelectCity } from 'hooks/useSelectCity.hook';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'routes/routes.util';
import { ICity } from 'store/API/cities/citiesApi';
import { setCitySelected } from 'store/API/report/reportSlice';
import { useAppDispatch } from 'store/storeHooks';
import find_city from '../../assets/find_city.jpg';

export const SelectCityPage: FC = () => {
  const {
    states,
    stateSelected,
    onHandleState,
    cities,
    citySelected,
    onHandleCity,
    isCityLoading
  } = useSelectCity();
  const dispatch = useAppDispatch();
  const query = useQuery();

  const navigate = useNavigate();

  const changeStoreCitySelected = (event: any, value: ICity | null) => {
    onHandleCity(event, value);

    if (value) {
      dispatch(setCitySelected({ city: value }));

      const goTo = query.get('irPara') ?? '';

      navigate(goTo ? `/${goTo}` : '/');
    }
  };

  const onCloseModal = useCallback(() => navigate('/'), []);

  return (
    <PageModal
      pageModalHeaderProps={{ closeShadow: onCloseModal, title: '' }}
      modalProps={{ open: true, sx: { height: '100%' } }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyItems: 'center',
          alignItems: 'center',
          gap: '0.5rem',
          width: '100%'
        }}
      >
        <Box sx={{ display: 'flex', maxHeight: '150px', maxWidth: '150px' }}>
          <img src={find_city} width="100%" height="100%" style={{ borderRadius: '25%' }} />
        </Box>
        <Typography
          sx={{ fontSize: '0.8rem', fontWeight: 'bold', textAlign: 'center' }}
          component="p"
        >
          Selecione uma cidade para saber o que está acontecendo
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', flex: 1, width: '100%' }}>
          <AutoCompleteInput
            options={states}
            label="Selecione o estado"
            valueSelected={stateSelected}
            onChange={onHandleState}
          />
          <AutoCompleteInput
            isLoading={isCityLoading}
            options={cities}
            label="Selecione a cidade"
            valueSelected={citySelected}
            onChange={changeStoreCitySelected}
          />
        </Box>
      </Box>
    </PageModal>
  );
};

export default SelectCityPage;

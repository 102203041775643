import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Box,
  CircularProgress,
  TextField
} from '@mui/material';
import React, { FC } from 'react';

interface AutoCompleteInputProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  options: any[];
  valueSelected: any;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>
  ) => void;
  helperText?: string | false;
  error?: boolean;
  label: string;
  id?: string;
}

const AutoCompleteInput: FC<AutoCompleteInputProps> = ({
  isLoading = false,
  isDisabled = false,
  options,
  valueSelected,
  onChange,
  helperText,
  error,
  label,
  id
}) => {
  return (
    <Autocomplete
      fullWidth={true}
      disabled={isDisabled}
      id={id}
      options={options}
      value={valueSelected}
      loading={isLoading}
      onChange={onChange}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {option.name}
        </Box>
      )}
      loadingText={'Carregando...'}
      renderInput={(params) => (
        <TextField
          helperText={helperText}
          error={error}
          label={label}
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
            color: 'primary',
            size: 'medium'
          }}
        />
      )}
    />
  );
};

export default AutoCompleteInput;

import { PageModal } from 'components/PageModal/PageModal';
import { FormikProps } from 'formik';
import { createContext, useCallback, useState } from 'react';
import { useGoToPage } from 'routes/routes.util';
import { useAppSelector } from 'store/storeHooks';
import { ProblemForm } from './ProblemForm';
import { ReportForm } from './ReportForm';
import { ISchemaReportForm, useReportForm } from './useReportForm';

interface ReportStepByStepContextProps {
  formik: FormikProps<ISchemaReportForm>;
}

export const ReportStepByStepContext = createContext<ReportStepByStepContextProps | null>(null);

export const ReportStepByStep = () => {
  const [step, setStep] = useState(1);
  const goTo = useGoToPage();
  const { formik } = useReportForm();
  const showMap = useAppSelector((e) => e.map.showMap);

  const onHandleCloseModel = useCallback(() => goTo({ to: '/' }), []);

  const onHandleGoStep = useCallback(() => setStep(2), []);
  const onHandleGoBack = useCallback(() => setStep(1), []);

  return (
    <PageModal
      modalProps={{
        open: true,
        hidden: showMap
      }}
      pageModalHeaderProps={{
        closeShadow: onHandleCloseModel,
        title: step === 1 ? 'Tipo de problema' : 'Formulário'
      }}
    >
      <ReportStepByStepContext.Provider value={{ formik }}>
        {step === 1 ? <ProblemForm go={onHandleGoStep} /> : <ReportForm back={onHandleGoBack} />}
      </ReportStepByStepContext.Provider>
    </PageModal>
  );
};

import { Box, TextareaAutosizeProps } from '@mui/material';
import { ErrorTypography } from 'components/form/ErrorTypography';
import { FC } from 'react';
import { TextAreaAutosizeStyled } from './TextArea.style';

interface ITextAreaAutosizeProps extends TextareaAutosizeProps {
  error?: boolean;
  messageError?: string;
  text: string;
  id: string;
  name: string;
  onChange: any;
}

export const TextArea: FC<ITextAreaAutosizeProps> = (props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '100%'
      }}
    >
      <TextAreaAutosizeStyled
        aria-label="minimum height"
        placeholder={props.text}
        name={props.name}
        value={props.value}
        id={props.id}
        onChange={props.onChange}
        style={{
          borderColor: props.error ? '#d32f2f' : '#D9D9D9'
        }}
      />
      {Boolean(props.error) && Boolean(props.messageError) ? (
        <ErrorTypography>{props.messageError}</ErrorTypography>
      ) : null}
    </Box>
  );
};

import { Box, Divider, Drawer, Typography } from '@mui/material';
import { CloseButton } from 'components/CloseButton/CloseButton';
import { useCallback } from 'react';
import { useGoToPage } from 'routes/routes.util';
import { useAppSelector } from 'store/storeHooks';
import { ContentMenuDrawer } from './components/ContentMenuDrawer/ContentMenuDrawer';
import FooterMenuDrawer from './components/FooterMenuDrawer/FooterMenuDrawer';
import { LoginSection } from './components/LoginSection/LoginSection';
import { UserInfo } from './components/UserInfo/UserInfo';
import {
  ContainerMenuDrawer,
  HeaderContainerMenuDrawer,
  MenuDrawerStyled
} from './MenuDrawer.style';

export const MenuDrawer = () => {
  const goTo = useGoToPage();
  const user = useAppSelector((e) => e.user.user);

  const onHandleCloseDrawer = useCallback(() => goTo({ to: '/' }), []);

  return (
    <MenuDrawerStyled anchor={'right'} open={true} onClose={onHandleCloseDrawer}>
      <ContainerMenuDrawer role="presentation">
        <HeaderContainerMenuDrawer>
          <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }} component="p">
            Menu
          </Typography>
          <CloseButton action={onHandleCloseDrawer} />
        </HeaderContainerMenuDrawer>
        <Divider variant="fullWidth" />
        {user ? <UserInfo /> : <LoginSection />}
        <Divider variant="fullWidth" />
        <ContentMenuDrawer />
        <FooterMenuDrawer />
      </ContainerMenuDrawer>
    </MenuDrawerStyled>
  );
};

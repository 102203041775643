import { Box, Button, Divider, Typography } from '@mui/material';
import CoffeeIcon from '@mui/icons-material/Coffee';

const FooterMenuDrawer = () => {
  return (
    <>
      <Divider variant="fullWidth" />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0.8rem',
          justifyItems: 'center'
        }}
      >
        <Button variant="outlined" sx={{ display: 'flex' }} startIcon={<CoffeeIcon />}>
          Buy me a coffee
        </Button>
        <Typography
          sx={{ display: 'flex', justifyContent: 'center', fontSize: '0.6rem' }}
          component="p"
        >
          Copyright © Samuel Santos, 2022
        </Typography>
      </Box>
    </>
  );
};

export default FooterMenuDrawer;

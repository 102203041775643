import { Avatar, Box, Divider } from '@mui/material';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { Skeleton } from '@mui/material';
import { CloseButton } from 'components/CloseButton/CloseButton';
import { ContainerReportCard } from './ReportCard.style';
import { ReportCardFooter } from './components/ReportCardFooter';
import { IReport } from 'store/API/report/reportInterfaces';

interface ReportCardProgressProps {
  showButtonClose?: boolean;
}

const ReportCardProgress: FC<ReportCardProgressProps> = ({ showButtonClose = true }) => {
  const navigate = useNavigate();

  return (
    <ContainerReportCard>
      {showButtonClose && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Skeleton sx={{ width: '80%' }} />
          <CloseButton action={() => navigate('/')} />
        </Box>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <Skeleton sx={{ width: '60%' }} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <Divider variant="fullWidth" sx={{ width: '100%' }} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '2px',
          width: '100%',
          alignItems: 'center'
        }}
      >
        <LocationCityIcon sx={{ height: '1rem', width: '1rem' }} />
        <Skeleton sx={{ width: '15%' }} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          gap: '0.5rem'
        }}
      >
        <Avatar />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'start'
          }}
        >
          <Skeleton sx={{ width: '25%', height: '40px' }} />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Skeleton sx={{ width: '100%', height: '100px' }} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <Divider variant="fullWidth" sx={{ width: '100%' }} />
      </Box>
      <ReportCardFooter
        isLoading={true}
        report={{ _id: '', location: { coordinates: [0, 0] } } as IReport}
        onHandleOpenComment={() => {}}
      />
    </ContainerReportCard>
  );
};

export default ReportCardProgress;

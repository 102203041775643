import { styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

interface IContainerCardTypeProblem extends BoxProps {
  cardColor: string;
}

export const ContainerCardTypeProblem = styled(Box)<IContainerCardTypeProblem>(
  ({ theme, cardColor }) => ({
    display: 'flex',
    width: '100%',
    borderWidth: '0.5px',
    borderStyle: 'solid',
    borderColor: cardColor !== 'theme' ? cardColor : theme.palette.primary.light,
    borderRadius: '0.5rem',
    padding: '0.5rem',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer'
  })
);

import React, { useCallback } from 'react';
import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom';

export const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export type RoutesPaths =
  | '/'
  | '/login'
  | '/resetPassword'
  | '/menu'
  | '/atualizar-perfil'
  | '/feed'
  | '/publicar'
  | '/post'
  | '/minhas-postagens'
  | '/cidade'
  | '/contato';

interface GoToPageProps {
  to: RoutesPaths;
  replace?: boolean;
  params?: string;
}

export const useGoToPage = () => {
  const navigate = useNavigate();

  return useCallback(({ to, replace = true, params = '' }: GoToPageProps) => {
    navigate(to + params, { replace: replace });
  }, []);
};

import React from 'react';
import { Box, Checkbox, Typography } from '@mui/material';
import anonymous from 'assets/anonymous.svg';
import { ContainerCardCheckAnonymous } from './ContainerCardCheckAnonymous.style';

interface CardCheckAnonymousProps {
  onHandleAnonymous(): void;
  value: boolean;
}

export const CardCheckAnonymous: React.FC<CardCheckAnonymousProps> = ({
  onHandleAnonymous,
  value
}) => {
  return (
    <ContainerCardCheckAnonymous
      onClick={onHandleAnonymous}
      cardColor={value ? 'theme' : '#D9D9D9'}
    >
      <img src={anonymous} alt="" />
      <Checkbox checked={value} />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column'
        }}
      >
        <Typography sx={{ fontSize: '0.9rem', fontWeight: 'bold' }} component="p">
          Publicar como uma pessoa anônima
        </Typography>
        <Typography sx={{ fontSize: '0.8rem', fontWeight: 'lighter' }} component="p">
          Seu nome não será exibido...
        </Typography>
      </Box>
    </ContainerCardCheckAnonymous>
  );
};

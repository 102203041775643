import { MenuDrawer } from 'pages/MenuDrawer/MenuDrawer';
import { LoginPage } from 'pages/LoginPage/LoginPage';
import { ResetPassword } from 'pages/ResetPassword/ResetPassword';
import { Routes as Switch, Route } from 'react-router-dom';
import { UpdateProfile } from 'pages/UpdateProfile/UpdateProfile';
import { ReportStepByStep } from 'pages/ReportStepByStep';
import { Report } from 'pages/Report/Report';
import { Feed } from 'pages/Feed/Feed';
import SelectCityPage from 'pages/SelectCityPage/SelectCityPage';
import { ProtectedRoute } from './ProtectedRoute';
import { MyReports } from 'pages/MyReports/MyReports';
import { NotAuthorized } from 'pages/NotAuthorized/NotAuthorized';
import { PageNotFound } from 'pages/PageNotFound/PageNotFound';
import { Contact } from 'pages/Contact/Contact';

const Routes = () => {
  return (
    <Switch>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/resetPassword" element={<ResetPassword />} />
      <Route path="/menu" element={<MenuDrawer />} />
      <Route path="/atualizar-perfil" element={<UpdateProfile />} />
      <Route path="/publicar" element={<ReportStepByStep />} />
      <Route path="/post/:id" element={<Report />} />
      <Route path="/feed" element={<Feed />} />
      <Route path="/cidade" element={<SelectCityPage />} />
      <Route path="/nao-autorizado" element={<NotAuthorized />} />
      <Route
        path="/minhas-postagens"
        element={
          <ProtectedRoute>
            <MyReports />
          </ProtectedRoute>
        }
      />
      <Route path="/contato" element={<Contact />} />
      <Route path="/" element={<></>} />
      <Route path="*" element={<PageNotFound />} />
    </Switch>
  );
};

export default Routes;

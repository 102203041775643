import { PageModal } from 'components/PageModal/PageModal';
import { useGetReportsToFeedMutation } from 'store/API/report/reportApi';
import { useAppSelector } from 'store/storeHooks';
import { ReportCard } from 'components/ReportCard/ReportCard';
import { useCallback, useEffect, useMemo } from 'react';
import { HeaderFeed } from './components/HeaderFeed';
import { useGetAllTypeReportQuery } from 'store/API/typeProblem/typeProblemApi';
import { Virtuoso } from 'react-virtuoso';
import { Box, CircularProgress, Typography } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ReportCardProgress from 'components/ReportCard/ReportCardProgress';
import { Navigate, useNavigate } from 'react-router';
import { DataNotFound } from 'components/DataNotFound/DataNotFound';

export const Feed = () => {
  const [getReportsToFeed, { isLoading: isLoadingReports, isError }] =
    useGetReportsToFeedMutation();
  const { data: problems = [], isLoading: isLoadingTypeProblem } = useGetAllTypeReportQuery();
  const user = useAppSelector((e) => e.user.user);
  const navigate = useNavigate();

  const isLoading = useMemo(
    () => isLoadingReports || isLoadingTypeProblem,
    [isLoadingReports, isLoadingTypeProblem]
  );

  const feedData = useAppSelector((e) => e.report.reportsFeed);
  const citySelected = useAppSelector((e) => e.report.citySelected);
  const hasNextPage = useAppSelector((e) => e.report.hasMoreReportToLoadOnFeed);

  const loadMore = () => {
    if (citySelected && !isLoadingReports && hasNextPage) {
      getReportsToFeed({ city_id: citySelected._id, skip: feedData.length });
    }
  };

  const typeProblem = useCallback((id: string) => problems.find((e) => e._id === id), [problems]);

  const showFakeReportCard = isLoading && feedData.length < 1;

  const onHandleNavigate = useCallback(() => {
    if (user) {
      navigate('/publicar');
    } else {
      navigate('/login?irPara=publicar');
    }
  }, [user]);

  useEffect(() => {
    if (citySelected && feedData.length < 1) {
      getReportsToFeed({ city_id: citySelected._id, skip: 0 });
    }
  }, [citySelected]);

  if (!citySelected) {
    return <Navigate to={{ pathname: '/cidade', search: 'irPara=feed' }} />;
  }

  return (
    <PageModal
      modalProps={{ open: true, sx: { height: '100%' } }}
      containerPageModalSx={{ height: isError || feedData.length < 1 ? undefined : 'inherit' }}
    >
      {(isError || feedData.length < 1) && !isLoading ? (
        <>
          <HeaderFeed />
          <DataNotFound
            title="Infelizmente, não encontramos nenhuma publicação em sua cidade."
            message="Seja o primeiro a relatar um problema"
            buttonTitle="Criar Publicação"
            action={onHandleNavigate}
          />
        </>
      ) : null}
      {!showFakeReportCard && feedData.length > 0 ? (
        <Virtuoso
          style={{ height: '100%', width: '100%' }}
          data={feedData}
          overscan={10}
          endReached={loadMore}
          totalCount={feedData.length}
          components={{
            Header: () => <HeaderFeed />,
            Footer: () => (hasNextPage ? <LoadingReports /> : <EndMessage />)
          }}
          itemContent={(index, report) => (
            <ReportCard
              reportCardStyle={{ marginBottom: '12px' }}
              key={index}
              hiddenCloseButton={true}
              report={{
                ...report,
                typeProblem: typeProblem(report.typeProblem as any) ?? report.typeProblem
              }}
            />
          )}
        />
      ) : null}
      {showFakeReportCard ? <FakePageLoading /> : null}
    </PageModal>
  );
};

const FakePageLoading = () => {
  return (
    <>
      <HeaderFeed />
      {[1, 2].map((e) => (
        <ReportCardProgress key={e} showButtonClose={false} />
      ))}
      <LoadingReports />
    </>
  );
};

const LoadingReports = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
    <CircularProgress key={0} size="16px" />
  </Box>
);

const EndMessage = () => (
  <Box
    sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
  >
    <TaskAltIcon />
    <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }} component="p">
      Você viu todas as publicações da sua cidade
    </Typography>
  </Box>
);

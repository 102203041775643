import { PageModal } from 'components/PageModal/PageModal';
import { useCallback } from 'react';
import { useGoToPage } from 'routes/routes.util';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { GenericTextField } from 'components/GenericTextField/GenericTextField';
import { Box, InputAdornment } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { TextArea } from 'components/TextArea/TextArea';
import { LoadingButton } from '@mui/lab';

const sendEmail = Yup.object({
  subject: Yup.string()
    .min(5, 'O mínimo é de 5 letras')
    .max(100, 'O máximo é 100 letras.')
    .required('O campo é obrigatório.'),
  message: Yup.string()
    .min(5, 'O mínimo é de 5 letras')
    .max(100, 'O máximo é 400 letras.')
    .required('O campo é obrigatório.'),
  email: Yup.string()
    .email('Email incompleto')
    .max(255)
    .required('É necessário informar um e-mail.')
}).defined();

export const Contact = () => {
  const goTo = useGoToPage();

  const { handleSubmit, control } = useForm({
    resolver: async (data, context, options) => {
      return yupResolver(sendEmail)(data, context, options);
    }
  });

  const onHandleCloseModal = useCallback(() => goTo({ to: '/' }), []);

  return (
    <PageModal
      closeModalOnShadow={onHandleCloseModal}
      pageModalHeaderProps={{ title: 'Deixe uma mensagem', closeShadow: onHandleCloseModal }}
      modalProps={{ open: true }}
    >
      <Controller
        name="subject"
        control={control}
        render={({ field }) => (
          <GenericTextField {...field} type={'subject'} label="Assunto*" id="subject" />
        )}
      />
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <GenericTextField
            {...field}
            type={'email'}
            label="Digite seu email*"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              )
            }}
            id="email"
          />
        )}
      />
      <Controller
        name="message"
        control={control}
        render={({ field }) => <TextArea {...field} id="message" name="message" text="Mensagem*" />}
      />
      <Box sx={{ display: 'flex', width: '100%', gap: '12px', justifyContent: 'flex-end' }}>
        <LoadingButton loading={false} onClick={() => {}} variant="outlined">
          Cancelar
        </LoadingButton>
        <LoadingButton loading={false} onClick={() => {}} variant="outlined">
          Enviar
        </LoadingButton>
      </Box>
    </PageModal>
  );
};

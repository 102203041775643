import InsertCommentIcon from '@mui/icons-material/InsertComment';
import NearMeIcon from '@mui/icons-material/NearMe';
import ShareIcon from '@mui/icons-material/Share';
import { Box, Typography } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useMap } from 'react-leaflet';
import { IReport } from 'store/API/report/reportInterfaces';
import { useNavigate } from 'react-router-dom';

interface ReportCardFooterProps {
  onHandleOpenComment: () => void;
  report: IReport;
  isLoading?: boolean;
}

export const ReportCardFooter: FC<ReportCardFooterProps> = ({
  onHandleOpenComment,
  report,
  isLoading = false
}) => {
  const {
    _id: report_id,
    location: { coordinates }
  } = report;

  const { enqueueSnackbar } = useSnackbar();
  const map = useMap();

  const navigate = useNavigate();

  const url = useMemo(() => {
    return `${location.origin}/post/${report_id}`;
  }, [report_id]);

  const onCopy = useCallback((text: string, result: boolean) => {
    if (result) {
      enqueueSnackbar('Link copiado!', { variant: 'success' });
    } else {
      enqueueSnackbar('Não foi possível copiar!', { variant: 'error' });
    }
  }, []);

  const onHangleGoMap = useCallback(() => {
    map.flyTo([coordinates[0], coordinates[1]], 18);
    navigate('/');
  }, [report]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between'
      }}
    >
      <ActionsFooterIcon
        icon={<InsertCommentIcon color={isLoading ? 'disabled' : 'primary'} fontSize="small" />}
        text={'Comentar'}
        action={onHandleOpenComment}
      />
      <ActionsFooterIcon
        icon={<NearMeIcon color={isLoading ? 'disabled' : 'primary'} fontSize="small" />}
        text={'Mapa'}
        action={onHangleGoMap}
      />
      <CopyToClipboard text={url} onCopy={onCopy}>
        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
          <ActionsFooterIcon
            key={'share'}
            icon={<ShareIcon color={isLoading ? 'disabled' : 'primary'} fontSize="small" />}
            text={'Compartilhar'}
          />
        </Box>
      </CopyToClipboard>
    </Box>
  );
};

interface ActionsFooterIconProps {
  icon: React.ReactNode;
  text: string;
  action?: () => void;
}

const ActionsFooterIcon: FC<ActionsFooterIconProps> = ({ action, icon, text }) => {
  return (
    <Box
      onClick={action}
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        gap: '1px',
        flex: 1,
        alignItems: 'center',
        cursor: 'pointer',
        justifyContent: 'center'
      }}
    >
      {icon}
      <Typography sx={{ fontSize: '12px' }} component="p">
        {text}
      </Typography>
    </Box>
  );
};

import FeatureButton from '../FeatureButton/FeatureButton';
import { ContentMenuDrawerStyled } from './ContentMenuDrawer.style';
import ListAltIcon from '@mui/icons-material/ListAlt';
import EmailIcon from '@mui/icons-material/Email';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import LogoutIcon from '@mui/icons-material/Logout';
import { signOut } from 'firebase/auth';
import { useAppDispatch, useAppSelector } from 'store/storeHooks';
import { useGoToPage } from 'routes/routes.util';
import { auth } from 'firebase-config';
import { signOutUser } from 'store/API/user/userSlice';
import { reportApi } from 'store/API/report/reportApi';

export const ContentMenuDrawer = () => {
  const user = useAppSelector((e) => e.user.user);
  const goTo = useGoToPage();

  const dispatch = useAppDispatch();

  const signout = async () => {
    await signOut(auth);
    dispatch(signOutUser());
    dispatch(reportApi.util.invalidateTags(['myReports']));
  };

  return (
    <ContentMenuDrawerStyled>
      {user ? (
        <FeatureButton
          onClick={() => goTo({ to: '/minhas-postagens' })}
          label="Meus relatórios"
          textButton="Ver meus relatórios"
          startIcon={<ListAltIcon />}
        />
      ) : null}
      {/*
        <FeatureButton
        onClick={() => {}}
        label="FAQ"
        textButton="Dúvidas Frequentes"
        startIcon={<QuestionAnswerIcon />}
      />
      <FeatureButton
        onClick={() => goTo({ to: '/contato' })}
        label="Contato"
        textButton="Enviar e-mail"
        startIcon={<EmailIcon />}
      />
        */}
      {user ? (
        <FeatureButton
          onClick={signout}
          label="Sair"
          textButton="Sair da conta"
          startIcon={<LogoutIcon />}
        />
      ) : null}
    </ContentMenuDrawerStyled>
  );
};

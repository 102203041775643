import { useMemo } from 'react';
import dayjs from 'dayjs';
import { months } from './months';

export const formatDate = (date: string, format: 'DD/MM/YYYY HH:mm:ss' | 'DD/MM/YYYY') =>
  useMemo(() => dayjs(date).format(format), [date]);

export const formatMonthDate = (date: string) =>
  useMemo(() => {
    const auxDate = dayjs(date);
    const day = auxDate.format('DD');
    const year = auxDate.get('year');

    const month = months[auxDate.get('M')];

    return `${day} de ${month} de ${year}`;
  }, [date]);

import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#293145'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {}
    }
  }
});

export const scrollStyle = {
  scrollbarWidth: 'thin',
  scrollbarColor: '#B7B7B7 transparent',
  '&::-webkit-scrollbar': {
    width: 6,
    height: 6,
    backgroundColor: 'transparent'
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#B7B7B7',
    minHeight: 24,
    minWidth: 24
  },
  '&::-webkit-scrollbar-thumb:focus': {
    backgroundColor: '#adadad'
  },
  '&::-webkit-scrollbar-thumb:active': {
    backgroundColor: '#adadad'
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#adadad'
  },
  '&::-webkit-scrollbar-corner': {
    backgroundColor: 'transparent'
  }
} as any;

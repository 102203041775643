import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import { FC } from 'react';

interface ReportFormFooterProps {
  go: () => void;
  back?: () => void;
  textGoButton?: string;
  textBack?: string;
  isLoading?: boolean;
}

export const ReportFormFooter: FC<ReportFormFooterProps> = ({
  back,
  go,
  textGoButton = 'Continuar',
  isLoading = false,
  textBack = 'Voltar'
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
        gap: '5px',
        width: '100%'
      }}
    >
      {back && (
        <LoadingButton loading={isLoading} onClick={back} variant="outlined">
          {textBack}
        </LoadingButton>
      )}
      <LoadingButton loading={isLoading} onClick={go} variant="outlined">
        {textGoButton}
      </LoadingButton>
    </Box>
  );
};

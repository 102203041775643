import { styled } from '@mui/material/styles';
import { Box, BoxProps, Drawer, DrawerProps } from '@mui/material';

export const paddingDrawer = '14px';

export const MenuDrawerStyled = styled(Drawer)<DrawerProps>(() => ({
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: '360px',
    overflowY: '',
    borderBottomLeftRadius: 25,
    borderTopLeftRadius: 25
  }
}));

export const ContainerMenuDrawer = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  gap: 1
}));

export const HeaderContainerMenuDrawer = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flex: 0,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: paddingDrawer,
  paddingRight: paddingDrawer,
  paddingTop: '8px',
  paddingBottom: '8px'
}));

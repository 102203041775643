import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FC, useCallback, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { ErrorTypography } from 'components/form/ErrorTypography';
import { useGetAllTypeReportQuery } from 'store/API/typeProblem/typeProblemApi';
import { ISchemaReportForm } from 'pages/ReportStepByStep/useReportForm';
import { FormikProps } from 'formik';

interface DropDownProblemProps {
  formik: FormikProps<ISchemaReportForm>;
}

export const DropDownProblem: FC<DropDownProblemProps> = ({ formik }) => {
  const { data: problems = [] } = useGetAllTypeReportQuery();

  const subTypeProblem = useMemo(
    () => formik.values.subTypeProblem,
    [formik.values.subTypeProblem]
  );

  const problemSelected = useMemo(() => formik.values.typeProblem, [formik.values.typeProblem]);

  const values = useMemo(
    () => problems.find((e) => e._id === problemSelected)?.subTypeReport ?? [],
    [problems, problemSelected]
  );

  const handleChange = useCallback((event: SelectChangeEvent) => {
    formik.setFieldValue('subTypeProblem', event.target.value);
  }, []);

  const error = Boolean(formik.touched.subTypeProblem && Boolean(formik.errors.subTypeProblem));

  return (
    <Box sx={{ display: 'flex', flex: 1, width: '100%', flexDirection: 'column' }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Especifique seu problema</InputLabel>
        <Select
          name="subTypeProblem"
          error={error}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={subTypeProblem}
          label="Especifique seu problema"
          onChange={handleChange}
        >
          {values.map((e) => (
            <MenuItem key={e._id} value={e._id}>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography component="p">{e.description}</Typography>
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {error ? <ErrorTypography>{formik.errors.subTypeProblem}</ErrorTypography> : null}
    </Box>
  );
};

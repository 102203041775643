import { styled } from '@mui/material/styles';
import { Box, BoxProps, Typography, TypographyStyle } from '@mui/material';

export const ContainerCustomDivider = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row'
}));

export const ContainerLineCustomDivider = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  width: '100%',
  flex: 1,
  alignItems: 'center'
}));

export const TextCustomDivider = styled(Typography)<TypographyStyle>(() => ({
  fontSize: '0.7rem',
  fontWeight: 'bold',
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  paddingRight: '8px',
  paddingLeft: '8px',
  flex: 0
}));

import { styled } from '@mui/material/styles';
import { Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';

const ErrorTypographyStyled = styled(Typography)<TypographyProps>(() => ({
  color: '#d32f2f',
  marginTop: '3px',
  marginRight: '14px',
  marginLeft: '14px',
  fontSize: '0.75rem'
}));

interface ErrorTypographyProps {
  children?: React.ReactNode;
}

export const ErrorTypography: FC<ErrorTypographyProps> = ({ children }) => {
  return <ErrorTypographyStyled>{children}</ErrorTypographyStyled>;
};

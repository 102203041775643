import { Box, Fab, Typography } from '@mui/material';
import { useCallback } from 'react';
import { setShowMap } from 'store/API/map/mapSlice';
import { useAppDispatch, useAppSelector } from 'store/storeHooks';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export const FabGoBackToReportForm = () => {
  const dispatch = useAppDispatch();
  const showMap = useAppSelector((e) => e.map.showMap);

  const onHandleBackToForm = useCallback(() => dispatch(setShowMap({ action: false })), []);

  if (!showMap) return null;

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: '15px',
        left: 0,
        right: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Fab
        onClick={onHandleBackToForm}
        variant="extended"
        size="small"
        color="primary"
        aria-label="add"
        sx={{
          display: 'flex',
          fontSize: 'smaller',
          minHeight: '34px',
          height: 'auto',
          gap: '2px'
        }}
      >
        <ArrowBackIosNewIcon fontSize="small" />
        Voltar para o formulário
      </Fab>
    </Box>
  );
};

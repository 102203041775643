import { Box, Typography } from '@mui/material';
import cityProblems from 'assets/city_problems.jpg';
import { FC, useCallback, useContext } from 'react';
import { useGetAllTypeReportQuery } from 'store/API/typeProblem/typeProblemApi';
import { ReportStepByStepContext } from '.';
import { ListProblems } from './components/ListProblems/ListProblems';
import { ReportFormFooter } from './components/ReportFormFooter';

interface ProblemFormProps {
  go: () => void;
}

export const ProblemForm: FC<ProblemFormProps> = ({ go }) => {
  const { data: problems = [], isLoading, isFetching } = useGetAllTypeReportQuery();
  const { formik } = useContext(ReportStepByStepContext)!;

  const onHandleValidate = useCallback(async () => {
    await formik.validateField('typeProblem');
    const isFieldvalidated = Boolean(formik.values.typeProblem);

    if (isFieldvalidated) {
      go();
    }
  }, [formik]);

  return (
    <>
      <Box sx={{ width: '230px', height: '150px' }}>
        <img
          style={{ borderRadius: '45px' }}
          width={'230px'}
          height={'150px'}
          src={cityProblems}
          alt=""
        />
      </Box>
      <Typography sx={{ fontSize: '0.8rem', fontWeight: 'lighter' }} component="p">
        Que tipo de problema em sua cidade você deseja reportar?
      </Typography>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold' }} component="p">
          Escolha uma opção que descreva melhor o seu problema
        </Typography>
      </Box>
      <ListProblems problems={problems} />
      <ReportFormFooter isLoading={isLoading || isFetching} go={onHandleValidate} />
    </>
  );
};

import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useDeleteCommentByIdMutation } from 'store/API/comment/commentApi';

interface useDeleteCommentProps {
  comment_id: string;
  report_id: string;
}

export function useDeleteComment({ comment_id, report_id }: useDeleteCommentProps) {
  const [openModalDeleteComment, setOpenModalDeleteComment] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [deleteComment, { isLoading }] = useDeleteCommentByIdMutation();

  const closeModalDeleteComment = useCallback(() => setOpenModalDeleteComment(false), []);

  const onHandleOpenModalDeleteComment = useCallback(() => setOpenModalDeleteComment(true), []);

  const onHandleDelete = useCallback(async () => {
    try {
      await deleteComment({ comment_id, report_id }).unwrap();
      enqueueSnackbar('O comentário foi deletado!', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Não foi possível deletar o comentário!', { variant: 'error' });
    }
  }, [comment_id, report_id]);

  return {
    openModalDeleteComment,
    closeModalDeleteComment,
    onHandleOpenModalDeleteComment,
    onHandleDelete,
    isLoadingDelete: isLoading
  };
}

import { styled } from '@mui/material/styles';
import { Box, BoxProps, Typography } from '@mui/material';

export const ContainerPageModalHeader = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%'
}));

export const ContainerTitlePageModalHeader = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flex: 2,
  flexDirection: 'column',
  justifyContent: 'center'
}));

export const TitlePageModalHeader = styled(Typography)(() => ({
  fontWeight: 'bold',
  fontSize: '0.7rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

import { DataNotFound } from 'components/DataNotFound/DataNotFound';
import { PageModal } from 'components/PageModal/PageModal';
import { useCallback } from 'react';
import { useGoToPage } from 'routes/routes.util';

export const NotAuthorized = () => {
  const goTo = useGoToPage();

  const onHandleCloseModal = useCallback(() => goTo({ to: '/' }), []);

  const onHandleLogin = useCallback(() => goTo({ to: '/login' }), []);

  return (
    <PageModal
      closeModalOnShadow={onHandleCloseModal}
      pageModalHeaderProps={{ title: 'Permissão negada!', closeShadow: onHandleCloseModal }}
      modalProps={{ open: true }}
    >
      <DataNotFound
        action={onHandleLogin}
        buttonTitle="Entrar ou Criar Conta"
        title="Você precisa estar logado para acessar a página"
      />
    </PageModal>
  );
};

import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { browserHistory } from 'App';
import { RootState } from 'store/store';

interface IInitialState {
  showMap: boolean;
}

interface ILatLng {
  lat: number;
  lng: number;
}

const initialState = {
  showMap: false
} as IInitialState;

const mapSlice = createSlice({
  name: 'mapSlice',
  initialState,
  reducers: {
    setShowMap: (state, { payload: { action } }: PayloadAction<{ action: boolean }>) => {
      state.showMap = action;
    }
  }
});

export const { setShowMap } = mapSlice.actions;

export const mapDblClick = createAsyncThunk('map/dbclick', (position: ILatLng, thunkApi) => {
  const appState = thunkApi.getState() as RootState;
  const isShowingMap = appState.map.showMap;
  const user_token = appState.user.token;
  const { dispatch } = thunkApi;

  const urlEncoded = encodeURI(`${position.lat}|${position.lng}`);

  if (isShowingMap && user_token) {
    browserHistory.push(`/publicar?latLng=${urlEncoded}`);
    dispatch(setShowMap({ action: false }));
  }

  if (!user_token) {
    browserHistory.push(`/login?irPara=publicar&latLng=${urlEncoded}`);
  }

  if (user_token && !isShowingMap) {
    browserHistory.push(`/publicar?latLng=${urlEncoded}`);
  }
});

export const mapReducer = mapSlice.reducer;

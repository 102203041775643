import React from 'react';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { createBrowserHistory } from 'history';
import { scrollStyle, theme } from './theme/theme';
import Routes from './routes/routes';
import Home from './pages/home';
import store from './store/store';

export const browserHistory = createBrowserHistory() as any;

function App() {
  return (
    <HistoryRouter history={browserHistory}>
      <Provider store={store}>
        <GlobalStyles styles={{ ...scrollStyle }} />
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <Home>
            <Routes />
          </Home>
        </ThemeProvider>
      </Provider>
    </HistoryRouter>
  );
}

export default App;

import { IUser, userApi } from './userApi';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type IUserState = {
  user: IUser | null;
  token: string | null;
  loginLoading: boolean;
};

const initialState = {
  user: null,
  token: null,
  loginLoading: false
} as IUserState;

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setCredentials: (state, { payload: { token } }: PayloadAction<{ token: string | null }>) => {
      state.token = token;
    },
    setLoginLoading: (state, { payload: { loading } }: PayloadAction<{ loading: boolean }>) => {
      state.loginLoading = loading;
    },
    signOutUser: (state) => {
      state.token = null;
      state.user = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(userApi.endpoints.getUser.matchFulfilled, (state, { payload }) => {
        state.user = payload.data;
      })
      .addMatcher(userApi.endpoints.createUser.matchFulfilled, (state, { payload }) => {
        state.user = payload.data;
      });
  }
});

export const { setCredentials, setLoginLoading, signOutUser } = userSlice.actions;

export default userSlice.reducer;

import { Box, Button, Typography } from '@mui/material';
import React from 'react';

interface IFeatureButton {
  startIcon?: React.ReactNode;
  label: string;
  textButton: string;
  onClick(): void;
}

const FeatureButton: React.FC<IFeatureButton> = ({ startIcon, label, textButton, onClick }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '0.8rem',
        paddingRight: '0.8rem'
      }}
    >
      <Typography sx={{ fontSize: '0.8rem' }} component="p">
        {label}
      </Typography>
      <Button
        onClick={onClick}
        startIcon={startIcon}
        variant="outlined"
        sx={{ display: 'flex', flex: 1, height: '40px' }}
      >
        {textButton}
      </Button>
    </Box>
  );
};

export default FeatureButton;

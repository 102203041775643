import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, IResponse } from '../api.util';

export interface ILocationPlace {
  type: string;
  coordinates: Array<number>;
}
export interface ICity {
  _id: string;
  id: number;
  uf: number;
  name: string;
  location: ILocationPlace;
}

export const citiesApi = createApi({
  reducerPath: 'citiesApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getCities: builder.mutation<ICity[], number>({
      query: (state_id) => `city?state_id=${state_id}`,
      transformResponse: (response: IResponse<ICity[]>) => {
        if (response.data) {
          return response.data;
        }

        return [];
      }
    })
  })
});

export const { useGetCitiesMutation } = citiesApi;

import { Avatar, Box, Typography } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { IComment } from 'store/API/comment/commentApi';
import { useAppSelector } from 'store/storeHooks';
import { formatDate } from 'utils/utils.hooks';
import { useDeleteComment } from '../hooks/useDeleteComment.hook';
import { useEditComment } from '../hooks/useEditComment.hook';
import { CommentCardContainer, CommentCardContent } from './CommentCard.style';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { CommentInput } from './CommentInput';
import { CloseButton } from 'components/CloseButton/CloseButton';
import { AnchorMenu } from 'components/AnchorMenu/AnchorMenu';
import { ModalConfirmation } from 'components/ModalConfirmation/ModalConfirmation';

interface CommentCardProps {
  commentData: IComment;
}

export const CommentCard: FC<CommentCardProps> = ({ commentData }) => {
  const {
    comment,
    user: { name, photo = '', _id: userComment_Id },
    _id: comment_id,
    report_id,
    updatedAt
  } = commentData;

  const user = useAppSelector((e) => e.user.user);

  const canEdit = useMemo(() => user?._id === userComment_Id, [user, userComment_Id]);
  const canDelete = useMemo(() => {
    if (!user) return null;

    const reports = ([...user.reports] ?? []) as string[];

    return Boolean(reports.find((e: string) => e === report_id)) || canEdit;
  }, [user, report_id, canEdit]);

  const {
    closeModalDeleteComment,
    isLoadingDelete,
    onHandleDelete,
    onHandleOpenModalDeleteComment,
    openModalDeleteComment
  } = useDeleteComment({ comment_id, report_id });

  const {
    openEditComment,
    onHandleOpenEditComment,
    isLoadingUpdateComment,
    onHandleCloseEditComment,
    onHandleUpdate
  } = useEditComment({ comment_id, comment, report_id });

  const date = formatDate(updatedAt, 'DD/MM/YYYY HH:mm:ss');

  const options = useMemo(() => {
    let menu = [];

    if (canEdit) {
      menu.push({
        label: 'Editar',
        icon: <EditIcon fontSize="small" />,
        action: onHandleOpenEditComment
      });
    }

    if (canDelete) {
      menu.push({
        label: 'Deletar',
        icon: <DeleteIcon fontSize="small" />,
        action: onHandleOpenModalDeleteComment
      });
    }

    return menu;
  }, [onHandleOpenModalDeleteComment, onHandleOpenEditComment, canEdit, canDelete]);

  return (
    <>
      <CommentCardContainer>
        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', flex: 0 }}>
          <Avatar
            imgProps={{ referrerPolicy: 'no-referrer' }}
            src={photo}
            sx={{ width: 24, height: 24 }}
            alt="H"
          ></Avatar>
        </Box>
        <CommentCardContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1px' }}>
            <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold' }} component="p">
              {name}
            </Typography>
            <Typography sx={{ fontSize: '0.6rem', lighter: '' }} component="p">
              {date}
            </Typography>
          </Box>
          {openEditComment ? (
            <CommentInput
              isLoading={isLoadingUpdateComment}
              initialComment={comment}
              onHandleSubmitComment={onHandleUpdate}
            />
          ) : (
            <Typography
              component="p"
              sx={{
                fontSize: '0.8rem',
                textAlign: 'justify',
                wordBreak: 'break-all'
              }}
            >
              {comment}
            </Typography>
          )}
        </CommentCardContent>
        <Box sx={{ display: 'flex', height: '34px', width: '34px' }}>
          {openEditComment ? (
            <CloseButton action={onHandleCloseEditComment} />
          ) : options.length > 0 ? (
            <AnchorMenu options={options} />
          ) : null}
        </Box>
      </CommentCardContainer>
      <ModalConfirmation
        open={openModalDeleteComment}
        severity={'warning'}
        closeModal={closeModalDeleteComment}
        title={'Excluindo a postagem'}
        message={'Tem certeza de que deseja excluir este comentário?'}
        isLoading={isLoadingDelete}
        onClickConfirm={onHandleDelete}
      />
    </>
  );
};

import React, { FC, ReactNode } from 'react';
import Modal, { ModalProps } from '@mui/material/Modal';
import { BackgroundPageModal, ContainerPageModal } from './PageModal.style';
import { PageModalHeader, PageModalHeaderProps } from './components/PageModalHeader';
import { SxProps, Theme } from '@mui/material';

interface ICustomModal {
  children?: ReactNode;
  modalProps: Omit<ModalProps, 'children'>;
  closeModalOnShadow?: () => void;
  pageModalHeaderProps?: PageModalHeaderProps;
  containerPageModalSx?: SxProps<Theme>;
}

export const PageModal: FC<ICustomModal> = ({
  modalProps,
  children,
  closeModalOnShadow,
  pageModalHeaderProps,
  containerPageModalSx = {}
}) => {
  return (
    <Modal {...modalProps}>
      <BackgroundPageModal onClick={closeModalOnShadow}>
        <ContainerPageModal sx={containerPageModalSx} onClick={(e) => e.stopPropagation()}>
          {pageModalHeaderProps && <PageModalHeader {...pageModalHeaderProps} />}
          {children}
        </ContainerPageModal>
      </BackgroundPageModal>
    </Modal>
  );
};

import { styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

export const ContainerReportCard = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '8px',
  borderRadius: '2rem',
  padding: '1rem 1rem',
  columnGap: '8px',
  backgroundColor: 'white'
}));

export const ReportCardTitle = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

export const ReportCardIconProblemContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '2px',
  width: '100%',
  alignItems: 'center'
}));

import { ICity } from '../cities/citiesApi';
import { IReport } from './reportInterfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { reportApi, REPORTS_TO_FEED_SKIP } from './reportApi';
import { LatLngTuple } from 'leaflet';

//SÃO PAULO
export const INITIAL_POSITION = [-23.5489, -46.6388] as LatLngTuple;

interface IInitialState {
  hasMoreReportToLoadOnFeed: boolean;
  citySelected: ICity | null;
  reportsMaps: IReport[];
  reportsFeed: IReport[];
}

const initialState = {
  reportsMaps: [],
  reportsFeed: [],
  citySelected: null,
  hasMoreReportToLoadOnFeed: true
} as IInitialState;

const reportSlice = createSlice({
  name: 'reportSlice',
  initialState,
  reducers: {
    setCitySelected: (state, { payload: { city } }: PayloadAction<{ city: ICity | null }>) => {
      state.citySelected = city;
      state.reportsFeed = [];
      state.hasMoreReportToLoadOnFeed = true;
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(reportApi.endpoints.publishReport.matchFulfilled, (state, { payload }) => {
        const allReports = state.reportsMaps;
        state.reportsMaps = [...allReports, payload.data];
      })
      .addMatcher(reportApi.endpoints.getReportsToFeed.matchFulfilled, (state, { payload }) => {
        if (payload.length < REPORTS_TO_FEED_SKIP) {
          state.hasMoreReportToLoadOnFeed = false;
        }
        state.reportsFeed = [...state.reportsFeed, ...payload];
      });
  }
});

export const { setCitySelected } = reportSlice.actions;

export default reportSlice.reducer;
